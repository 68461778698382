import {
  AggregateRating,
  Book,
  BorrowAction,
  CreativeWorkSeries,
  Offer,
  Organization,
  Person,
  Product as ProductSchema,
} from 'schema-dts'
import {
  getLocalizedPrice,
  timestampToDate,
  secondsToDurationString,
} from './localizationFormatter'

import { createSlug } from './slug'
import {
  Comment,
  Format,
  GenericName,
  Product,
} from '~/plugins/hummingbird/api-types/CatalogProduct'
import { NextoryContext } from '~/nx-types'

// Helper function to create meta elements for the `head()` of Nuxt pages
export function metaElements(
  title: string,
  description?: string,
  image?: string
) {
  if (image) {
    image = image?.replace(
      '{$width}',
      '630' // recommended max width for OpenGraph images
    )
  }

  const imageMetaElements = image
    ? [
        {
          hid: 'og:image:secure_url',
          property: 'og:image:secure_url',
          content: image,
        },
        {
          property: 'twitter:image',
          content: image,
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
      ]
    : []

  const descriptionMetaElements = description
    ? [
        {
          hid: 'og:description',
          property: 'og:description',
          content: description,
        },
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
      ]
    : []

  return {
    title,
    meta: [
      {
        hid: 'og:title',
        property: 'og:title',
        content: title,
      },
      ...descriptionMetaElements,
      ...imageMetaElements,
    ],
  }
}

export function getRecommendedImageFromProducts(products: Product[]) {
  const allFormats = products.flatMap(product => product.formats)

  return getRecommendedCoverFromFormats(allFormats)?.img_url
}

export function getRecommendedCoverFromFormats(
  formats: Product['formats']
): Format | undefined {
  if (formats.length === 0) {
    return undefined
  }

  // We prefer square images
  let format = formats.find(format => format.cover_ratio === 1)
  if (!format) {
    // If there are no square images, fallback to the first format available
    format = formats[0]
  }

  return format
}

export function getStructuredOffer(ctx: NextoryContext): Offer {
  const { $link, i18n, localePath, store } = ctx

  const minPrice = store.state.webConfig.config.minimumPrice
  const maxPrice = store.state.webConfig.config.maximumPrice

  const planFormUrl = $link.absolute(
    localePath({
      name: 'register-planform',
    })
  )

  return {
    '@type': 'Offer',
    url: planFormUrl,
    priceSpecification: {
      '@type': 'PriceSpecification',
      price: 0, // we've got a free trial
      maxPrice: maxPrice.amount,
      priceCurrency: maxPrice.currency,
      valueAddedTaxIncluded: true,
    },
    description: i18n
      .t(
        store.state.webConfig.config.features.hasMagazines
          ? 'homepage.hero.body.magazine_market'
          : 'homepage.hero.body',
        {
          price: getLocalizedPrice(minPrice.amount, minPrice.currency, ctx),
        }
      )
      .toString(),
    availability: 'https://schema.org/InStock',
  }
}

export function getStructuredBreadcrumb(
  ctx: NextoryContext,
  breadcrumb: Array<{ href: string; name: string }>
) {
  if (!breadcrumb) return {}

  const { $link } = ctx

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListOrder: 'Ascending',
    itemListElement: breadcrumb.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item.name,
      item: $link.absolute(item.href),
    })),
  }
}

export function getStructuredSearchAction({
  $link,
  localePath,
}: NextoryContext) {
  const searchUrl = $link.absolute(
    localePath({
      name: 'search',
    })
  )

  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    '@id': 'urn:nextory:search',
    url: $link.absolute(
      localePath({
        name: 'index',
      })
    ),
    potentialAction: {
      '@type': 'SearchAction',
      target: `${searchUrl}?q={search_term}`,
      'query-input': 'required name=search_term',
    },
  }
}

export function getStructuredPerson(
  { $link, localePath }: NextoryContext,
  persons: GenericName[],
  pageName: string,
  type: 'Author' | 'Narrator'
): Person[] {
  return persons.map(person => ({
    '@type': 'Person',
    '@id': `urn:nextory:${type.toLowerCase()}:${person.id}`,
    name: person.name,
    url: $link.absolute(
      localePath({
        name: pageName,
        params: {
          slug: createSlug(person.name, person.id),
        },
      })
    ),
  }))
}

export function getStructuredOrganization(
  name: string,
  id: number | string,
  type: string
): Organization {
  return {
    // @ts-ignore
    '@context': 'https://schema.org',
    // @ts-ignore
    '@type': 'Organization',
    '@id': `urn:nextory:${type.toLowerCase()}:${id}`,
    name,
  }
}

export function getStructuredSeries(
  { $link, localePath }: NextoryContext,
  book: Product
): CreativeWorkSeries | undefined {
  if (!book.series) return undefined

  const seriesUrl = $link.absolute(
    localePath({
      name: 'series-slug',
      params: {
        slug: createSlug(book.series.name, book.series.id),
      },
    })
  )

  return {
    '@type': 'CreativeWorkSeries',
    '@id': `urn:nextory:series:${book.series.id}`,
    name: book.series.name,
    hasPart: {
      '@type': 'Book',
      position: book.volume,
    },
    url: seriesUrl,
  }
}

export function getStructuredPotentialAction(
  { $link, localePath }: NextoryContext,
  name: string,
  isbn: string
): BorrowAction {
  const planFormUrl = $link.absolute(
    localePath({
      name: 'register-planform',
    })
  )

  return {
    // Tell that the book can be read on mobile devices
    '@type': 'BorrowAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: planFormUrl,
      actionPlatform: [
        // 'https/DesktopWebPlatform', <-- uncommented when webreader is developed 😉
        'https://schema.org/IOSPlatform',
        'https://schema.org/AndroidPlatform',
      ],
    },
    result: {
      '@type': 'Book',
      name,
      isbn,
    },
    lender: {
      '@type': 'LibrarySystem',
      name: 'Nextory',
      '@id': 'https://nextory.com/',
    },
  }
}

// Note: If the book doesn't have an author, but rather, one or more contributors,
// include these other contributors as defined in schema.org/Book.
//
// Conversely, if a book's author is an organization, define the author accordingly
// as defined in schema.org/Organization.
export function getStructuredBookAuthor(
  ctx: NextoryContext,
  book: Product
): Person[] | Organization | undefined {
  if (book.authors.length === 0) {
    const publisher = book.formats[0]?.publisher
    if (!publisher) return undefined
    return getStructuredOrganization(publisher.name, publisher.id, 'publisher')
  } else {
    return getStructuredPerson(ctx, book.authors, 'author-slug', 'Author')
  }
}

export function getStructuredBookEntity(
  ctx: NextoryContext,
  book: Product,
  productCategories: []
): Book | ProductSchema {
  const { $link, localePath } = ctx

  const bookUrl = $link.absolute(
    localePath({
      name: 'book-slug',
      params: {
        slug: createSlug(book.title, book.id),
      },
    })
  )

  return {
    '@id': `urn:nextory:book-id:${book.id}`,
    '@type': 'Book',
    author: getStructuredBookAuthor(ctx, book),
    name: book.title,
    url: bookUrl,
    description: book.description_full,
    // "sameAs": Add later when more information in DB
    // "aggregateRating": Fix this issue first: Review has multiple aggregate ratings
    // "review" : Fix this issue first: Review has multiple aggregate ratings
    offers: getStructuredOffer(ctx),
    genre: getLastLevelTitles(productCategories),
    isPartOf: getStructuredSeries(ctx, book),
    isFamilyFriendly: !book.is_adult_book,
  }
}

export function getStructuredProduct(
  ctx: NextoryContext,
  book: Product,
  comments: Comment[],
  productCategories: []
): ProductSchema {
  const { $link, i18n, localePath } = ctx

  const bookUrl = $link.absolute(
    localePath({
      name: 'book-slug',
      params: {
        slug: createSlug(book.title, book.id),
      },
    })
  )

  let optionalReviewProperties

  // We only include reviews if there are any
  if (comments.length > 0) {
    optionalReviewProperties = {
      review: comments.map((comment: Comment) => ({
        '@type': 'Review',
        '@id': `urn:nextory:book-id:${book.id}:review:${comment.id}`,
        author: {
          '@id': `urn:nextory:book-id:${book.id}:review:${comment.id}#author`,
          '@type': 'Person',
          name: comment.pseudo || i18n.t('catalogue.book.comment.anonymous'),
        },
        datePublished: timestampToDate(comment.created_at),
        reviewBody: comment.text,
        reviewRating: {
          '@id': `urn:nextory:book-id:${book.id}:review:${comment.id}#reviewRating`,
          '@type': 'Rating',
          ratingValue: Math.min(Math.max(comment.rating, 1), 5),
        },
      })),
    }
  }

  return {
    '@id': `urn:nextory:product-id:${book.id}`,
    '@type': 'Product',
    additionalType: 'Book',
    name: book.title,
    // @ts-ignore - author is not a property of Product and does
    // not follow schema.org but recomanded by the SEO comsultant.
    author: getStructuredBookAuthor(ctx, book),
    // @ts-ignore - inLanguage is not a property of Product and does
    // not follow schema.org but recomanded by the SEO comsultant.
    inLanguage: book.language,
    url: bookUrl,
    description: book.description_full,
    // "sameAs": Add later when more information in DB
    aggregateRating: getBookAggregateRating(book, true),
    // "review" optional
    ...optionalReviewProperties,
    image: getRecommendedCoverFromFormats(book.formats)?.img_url,
    isFamilyFriendly: !book.is_adult_book,
    category: getLastLevelTitles(productCategories),
    // @ts-ignore - workExample is not a property of Product and does
    // not follow schema.org but recomanded by the SEO comsultant.
    workExample: getStructuredBookEdition(ctx, book),
  }
}

export function getStructuredBookEdition(
  ctx: NextoryContext,
  book: Product
): Book[] {
  const { $link, localePath } = ctx

  const bookUrl = $link.absolute(
    localePath({
      name: 'book-slug',
      params: {
        slug: createSlug(book.title, book.id),
      },
    })
  )

  return [
    // Book formats - Generate each format available for the current book (ePub, PDF, audio, ...)
    ...book.formats.map((format: Format): Book => {
      let additionalProperties

      if (format.type === 'hls') {
        additionalProperties = {
          duration: secondsToDurationString(format.duration),
          readBy: getStructuredPerson(
            ctx,
            book.narrators,
            'narrator-slug',
            'Narrator'
          ),
        }
      }

      return {
        '@id': `urn:isbn:${format.isbn}`,
        '@type': format.type === 'hls' ? 'Audiobook' : 'Book',
        bookFormat:
          format.type === 'hls'
            ? 'https://schema.org/AudiobookFormat'
            : 'https://schema.org/EBook',
        inLanguage: book.language,
        isbn: format.isbn,
        potentialAction: getStructuredPotentialAction(
          ctx,
          book.title,
          format.isbn
        ),
        // author: Set only if different from BookEntity not the case here
        // bookEdition: Add later when more information in DB
        datePublished: format.publication_date,
        // identifier: Add later when more information in DB
        // sameAs: Add later when more information in DB
        name: book.title,
        url: bookUrl,
        image: format.img_url.replace('{$width}', '200'), // don't use bookCoverUrl, since it's not the same for all formats
        publisher: {
          '@type': 'Organization',
          name: format.publisher.name,
        },
        ...additionalProperties,
      }
    }),
  ]
}

export function getStructuredBooks(
  ctx: NextoryContext,
  book: Product,
  comments: Comment[],
  productCategories: []
): Book {
  const { $link, localePath } = ctx
  const bookUrl = $link.absolute(
    localePath({
      name: 'book-slug',
      params: {
        slug: createSlug(book.title, book.id),
      },
    })
  )

  return {
    // @ts-ignore
    '@context': 'https://schema.org',
    ...getStructuredBookEntity(ctx, book, productCategories),
    workExample: getStructuredBookEdition(ctx, book),
  }
}

export function getStructuredProducts(
  ctx: NextoryContext,
  book: Product,
  comments: Comment[],
  productCategories: []
): ProductSchema {
  const { $link, localePath } = ctx
  const bookUrl = $link.absolute(
    localePath({
      name: 'book-slug',
      params: {
        slug: createSlug(book.title, book.id),
      },
    })
  )

  return {
    // @ts-ignore
    '@context': 'https://schema.org',
    ...getStructuredProduct(ctx, book, comments, productCategories),
  }
}

export function getNextoryOrganization(ctx: NextoryContext) {
  const { $link, localePath } = ctx
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Nextory',
    url: $link.absolute(
      localePath({
        name: 'index',
      })
    ),
    logo: `${process.env.NEXTORY_WEB_URL}logo-nextory.png`,
    sameAs: [
      'https://www.facebook.com/nextory/',
      'https://www.linkedin.com/company/nextory',
      'https://www.youtube.com/channel/UC2IqgcVr3n-ekah1S1w7hHw/',
      'https://www.pinterest.se/nextorycom/',
    ],
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'SE', // Need to be in ISO 3166-2
      addressLocality: 'Stockholm',
      streetAddress: 'Norrtullsgatan 6',
      postalCode: '11329',
    },
  }
}

// Extracts the titles of the last-level sub-categories from the provided array
function getLastLevelTitles(categories: Array<[]>): string[] {
  interface CategoryList {
    id: number
    slug: string
    title: string
  }

  if (!categories) return ['Unknown']

  return categories.map((category: CategoryList[]) => {
    const categoryTitle = category[category.length - 1]?.title
    return categoryTitle || 'Unknown'
  })
}

/**
 * Retrieves the aggregate rating shema for a book.
 *
 * @param book - The book object to get the reviews count and value.
 * @param addNextoryBookId - Optional parameter (needed in the book product NOT in
 * book format) indicating whether the Nextory book ID as to be added in the shema.
 * @returns The aggregate rating object or undefined if the book has no ratings.
 */
function getBookAggregateRating(
  book: Product,
  addNextoryBookId: Boolean = false
): AggregateRating | undefined {
  if (book.number_of_rates === 0) {
    return undefined
  }

  const ratingValue = Math.min(Math.max(book.average_rating, 1), 5)
  const ratingCount = book.number_of_rates

  if (addNextoryBookId) {
    return {
      '@type': 'AggregateRating',
      '@id': `urn:nextory:book-id:${book.id}#aggregateRating`,
      ratingValue,
      ratingCount,
    }
  }

  return {
    '@type': 'AggregateRating',
    ratingValue,
    ratingCount,
  }
}
