export const LazyHydrateEsm = () => import('../../node_modules/vue-lazy-hydration/dist/LazyHydrate.esm.js' /* webpackChunkName: "components/lazy-hydrate-esm" */).then(c => wrapFunctional(c.default || c))
export const LazyHydrateEsmMin = () => import('../../node_modules/vue-lazy-hydration/dist/LazyHydrate.esm.min.js' /* webpackChunkName: "components/lazy-hydrate-esm-min" */).then(c => wrapFunctional(c.default || c))
export const LazyHydrate = () => import('../../node_modules/vue-lazy-hydration/dist/LazyHydrate.js' /* webpackChunkName: "components/lazy-hydrate" */).then(c => wrapFunctional(c.default || c))
export const LazyHydrateMin = () => import('../../node_modules/vue-lazy-hydration/dist/LazyHydrate.min.js' /* webpackChunkName: "components/lazy-hydrate-min" */).then(c => wrapFunctional(c.default || c))
export const BooksList = () => import('../../components/BooksList.vue' /* webpackChunkName: "components/books-list" */).then(c => wrapFunctional(c.default || c))
export const ButtonAppleLogin = () => import('../../components/ButtonAppleLogin.vue' /* webpackChunkName: "components/button-apple-login" */).then(c => wrapFunctional(c.default || c))
export const ButtonChangeSubscription = () => import('../../components/ButtonChangeSubscription.vue' /* webpackChunkName: "components/button-change-subscription" */).then(c => wrapFunctional(c.default || c))
export const ButtonGoogleLogin = () => import('../../components/ButtonGoogleLogin.vue' /* webpackChunkName: "components/button-google-login" */).then(c => wrapFunctional(c.default || c))
export const ButtonHelp = () => import('../../components/ButtonHelp.vue' /* webpackChunkName: "components/button-help" */).then(c => wrapFunctional(c.default || c))
export const ChangePasswordWithToken = () => import('../../components/ChangePasswordWithToken.vue' /* webpackChunkName: "components/change-password-with-token" */).then(c => wrapFunctional(c.default || c))
export const CurrentSubscriptionIndicator = () => import('../../components/CurrentSubscriptionIndicator.vue' /* webpackChunkName: "components/current-subscription-indicator" */).then(c => wrapFunctional(c.default || c))
export const DownloadApps = () => import('../../components/DownloadApps.vue' /* webpackChunkName: "components/download-apps" */).then(c => wrapFunctional(c.default || c))
export const KonamiCode = () => import('../../components/KonamiCode.vue' /* webpackChunkName: "components/konami-code" */).then(c => wrapFunctional(c.default || c))
export const LaunchAppUnderwayFaq = () => import('../../components/LaunchAppUnderwayFaq.vue' /* webpackChunkName: "components/launch-app-underway-faq" */).then(c => wrapFunctional(c.default || c))
export const LaunchAppUnderwayFaqQuestion = () => import('../../components/LaunchAppUnderwayFaqQuestion.vue' /* webpackChunkName: "components/launch-app-underway-faq-question" */).then(c => wrapFunctional(c.default || c))
export const LaunchAppUnderwayHeroBanner = () => import('../../components/LaunchAppUnderwayHeroBanner.vue' /* webpackChunkName: "components/launch-app-underway-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const LaunchAppUnderwaySlider = () => import('../../components/LaunchAppUnderwaySlider.vue' /* webpackChunkName: "components/launch-app-underway-slider" */).then(c => wrapFunctional(c.default || c))
export const LaunchAppUnderwayThreeQuestions = () => import('../../components/LaunchAppUnderwayThreeQuestions.vue' /* webpackChunkName: "components/launch-app-underway-three-questions" */).then(c => wrapFunctional(c.default || c))
export const LoadingPlaceholder = () => import('../../components/LoadingPlaceholder.vue' /* webpackChunkName: "components/loading-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PageContainer = () => import('../../components/PageContainer.vue' /* webpackChunkName: "components/page-container" */).then(c => wrapFunctional(c.default || c))
export const PopIn = () => import('../../components/PopIn.vue' /* webpackChunkName: "components/pop-in" */).then(c => wrapFunctional(c.default || c))
export const QrCodePhone = () => import('../../components/QrCodePhone.vue' /* webpackChunkName: "components/qr-code-phone" */).then(c => wrapFunctional(c.default || c))
export const ResetPassword = () => import('../../components/ResetPassword.vue' /* webpackChunkName: "components/reset-password" */).then(c => wrapFunctional(c.default || c))
export const SignIn = () => import('../../components/SignIn.vue' /* webpackChunkName: "components/sign-in" */).then(c => wrapFunctional(c.default || c))
export const SignUp = () => import('../../components/SignUp.vue' /* webpackChunkName: "components/sign-up" */).then(c => wrapFunctional(c.default || c))
export const StarRating = () => import('../../components/StarRating.vue' /* webpackChunkName: "components/star-rating" */).then(c => wrapFunctional(c.default || c))
export const StickyButton = () => import('../../components/StickyButton.vue' /* webpackChunkName: "components/sticky-button" */).then(c => wrapFunctional(c.default || c))
export const StoreButtons = () => import('../../components/StoreButtons.vue' /* webpackChunkName: "components/store-buttons" */).then(c => wrapFunctional(c.default || c))
export const TimeLimitedCircleGraph = () => import('../../components/TimeLimitedCircleGraph.vue' /* webpackChunkName: "components/time-limited-circle-graph" */).then(c => wrapFunctional(c.default || c))
export const AccordionItem = () => import('../../components/Accordion/AccordionItem.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c))
export const AccordionLink = () => import('../../components/Accordion/AccordionLink.vue' /* webpackChunkName: "components/accordion-link" */).then(c => wrapFunctional(c.default || c))
export const AccountEdit = () => import('../../components/Account/AccountEdit.vue' /* webpackChunkName: "components/account-edit" */).then(c => wrapFunctional(c.default || c))
export const AccountEmail = () => import('../../components/Account/AccountEmail.vue' /* webpackChunkName: "components/account-email" */).then(c => wrapFunctional(c.default || c))
export const AccountInformations = () => import('../../components/Account/AccountInformations.vue' /* webpackChunkName: "components/account-informations" */).then(c => wrapFunctional(c.default || c))
export const AccountNextSubscription = () => import('../../components/Account/AccountNextSubscription.vue' /* webpackChunkName: "components/account-next-subscription" */).then(c => wrapFunctional(c.default || c))
export const AccountPassword = () => import('../../components/Account/AccountPassword.vue' /* webpackChunkName: "components/account-password" */).then(c => wrapFunctional(c.default || c))
export const AccountPaymentInfos = () => import('../../components/Account/AccountPaymentInfos.vue' /* webpackChunkName: "components/account-payment-infos" */).then(c => wrapFunctional(c.default || c))
export const AccountReadingActivity = () => import('../../components/Account/AccountReadingActivity.vue' /* webpackChunkName: "components/account-reading-activity" */).then(c => wrapFunctional(c.default || c))
export const AccountReadingActivityFeed = () => import('../../components/Account/AccountReadingActivityFeed.vue' /* webpackChunkName: "components/account-reading-activity-feed" */).then(c => wrapFunctional(c.default || c))
export const AccountRenewalInformation = () => import('../../components/Account/AccountRenewalInformation.vue' /* webpackChunkName: "components/account-renewal-information" */).then(c => wrapFunctional(c.default || c))
export const AccountSubscription = () => import('../../components/Account/AccountSubscription.vue' /* webpackChunkName: "components/account-subscription" */).then(c => wrapFunctional(c.default || c))
export const AccountSubscriptionRenewalPrompt = () => import('../../components/Account/AccountSubscriptionRenewalPrompt.vue' /* webpackChunkName: "components/account-subscription-renewal-prompt" */).then(c => wrapFunctional(c.default || c))
export const AlertMessage = () => import('../../components/Alert/AlertMessage.vue' /* webpackChunkName: "components/alert-message" */).then(c => wrapFunctional(c.default || c))
export const CancellationCancelCard = () => import('../../components/Cancellation/CancellationCancelCard.vue' /* webpackChunkName: "components/cancellation-cancel-card" */).then(c => wrapFunctional(c.default || c))
export const CancellationDowngradeCard = () => import('../../components/Cancellation/CancellationDowngradeCard.vue' /* webpackChunkName: "components/cancellation-downgrade-card" */).then(c => wrapFunctional(c.default || c))
export const CancellationExtendCard = () => import('../../components/Cancellation/CancellationExtendCard.vue' /* webpackChunkName: "components/cancellation-extend-card" */).then(c => wrapFunctional(c.default || c))
export const CancellationMessages = () => import('../../components/Cancellation/CancellationMessages.vue' /* webpackChunkName: "components/cancellation-messages" */).then(c => wrapFunctional(c.default || c))
export const CancellationRevertCard = () => import('../../components/Cancellation/CancellationRevertCard.vue' /* webpackChunkName: "components/cancellation-revert-card" */).then(c => wrapFunctional(c.default || c))
export const CatalogBookCard = () => import('../../components/Catalog/CatalogBookCard.vue' /* webpackChunkName: "components/catalog-book-card" */).then(c => wrapFunctional(c.default || c))
export const CatalogBookComments = () => import('../../components/Catalog/CatalogBookComments.vue' /* webpackChunkName: "components/catalog-book-comments" */).then(c => wrapFunctional(c.default || c))
export const CatalogBookDesc = () => import('../../components/Catalog/CatalogBookDesc.vue' /* webpackChunkName: "components/catalog-book-desc" */).then(c => wrapFunctional(c.default || c))
export const CatalogBookInformations = () => import('../../components/Catalog/CatalogBookInformations.vue' /* webpackChunkName: "components/catalog-book-informations" */).then(c => wrapFunctional(c.default || c))
export const CatalogBreadcrumb = () => import('../../components/Catalog/CatalogBreadcrumb.vue' /* webpackChunkName: "components/catalog-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategories = () => import('../../components/Catalog/CatalogCategories.vue' /* webpackChunkName: "components/catalog-categories" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesSkeleton = () => import('../../components/Catalog/CatalogCategoriesSkeleton.vue' /* webpackChunkName: "components/catalog-categories-skeleton" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoryTopLevelCard = () => import('../../components/Catalog/CatalogCategoryTopLevelCard.vue' /* webpackChunkName: "components/catalog-category-top-level-card" */).then(c => wrapFunctional(c.default || c))
export const CatalogPagination = () => import('../../components/Catalog/CatalogPagination.vue' /* webpackChunkName: "components/catalog-pagination" */).then(c => wrapFunctional(c.default || c))
export const CatalogProductCollection = () => import('../../components/Catalog/CatalogProductCollection.vue' /* webpackChunkName: "components/catalog-product-collection" */).then(c => wrapFunctional(c.default || c))
export const CatalogSelectionHeader = () => import('../../components/Catalog/CatalogSelectionHeader.vue' /* webpackChunkName: "components/catalog-selection-header" */).then(c => wrapFunctional(c.default || c))
export const CatalogSeriesCard = () => import('../../components/Catalog/CatalogSeriesCard.vue' /* webpackChunkName: "components/catalog-series-card" */).then(c => wrapFunctional(c.default || c))
export const CatalogWantMore = () => import('../../components/Catalog/CatalogWantMore.vue' /* webpackChunkName: "components/catalog-want-more" */).then(c => wrapFunctional(c.default || c))
export const ContentBoxes = () => import('../../components/Content/ContentBoxes.vue' /* webpackChunkName: "components/content-boxes" */).then(c => wrapFunctional(c.default || c))
export const ContentCustomerRating = () => import('../../components/Content/ContentCustomerRating.vue' /* webpackChunkName: "components/content-customer-rating" */).then(c => wrapFunctional(c.default || c))
export const ContentDivider = () => import('../../components/Content/ContentDivider.vue' /* webpackChunkName: "components/content-divider" */).then(c => wrapFunctional(c.default || c))
export const ContentHero = () => import('../../components/Content/ContentHero.vue' /* webpackChunkName: "components/content-hero" */).then(c => wrapFunctional(c.default || c))
export const ContentIllustratedDetails = () => import('../../components/Content/ContentIllustratedDetails.vue' /* webpackChunkName: "components/content-illustrated-details" */).then(c => wrapFunctional(c.default || c))
export const ContentImageAndText = () => import('../../components/Content/ContentImageAndText.vue' /* webpackChunkName: "components/content-image-and-text" */).then(c => wrapFunctional(c.default || c))
export const ContentImagesAndLinks = () => import('../../components/Content/ContentImagesAndLinks.vue' /* webpackChunkName: "components/content-images-and-links" */).then(c => wrapFunctional(c.default || c))
export const ContentMultipleImageAndText = () => import('../../components/Content/ContentMultipleImageAndText.vue' /* webpackChunkName: "components/content-multiple-image-and-text" */).then(c => wrapFunctional(c.default || c))
export const ContentParallax = () => import('../../components/Content/ContentParallax.vue' /* webpackChunkName: "components/content-parallax" */).then(c => wrapFunctional(c.default || c))
export const ContentRating = () => import('../../components/Content/ContentRating.vue' /* webpackChunkName: "components/content-rating" */).then(c => wrapFunctional(c.default || c))
export const ContentSellingPoints = () => import('../../components/Content/ContentSellingPoints.vue' /* webpackChunkName: "components/content-selling-points" */).then(c => wrapFunctional(c.default || c))
export const ContentShowCase = () => import('../../components/Content/ContentShowCase.vue' /* webpackChunkName: "components/content-show-case" */).then(c => wrapFunctional(c.default || c))
export const ContentUserReview = () => import('../../components/Content/ContentUserReview.vue' /* webpackChunkName: "components/content-user-review" */).then(c => wrapFunctional(c.default || c))
export const FlashMessage = () => import('../../components/Flash/FlashMessage.vue' /* webpackChunkName: "components/flash-message" */).then(c => wrapFunctional(c.default || c))
export const FlashMessages = () => import('../../components/Flash/FlashMessages.vue' /* webpackChunkName: "components/flash-messages" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigation = () => import('../../components/Header/HeaderNavigation.vue' /* webpackChunkName: "components/header-navigation" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearch = () => import('../../components/Header/HeaderSearch.vue' /* webpackChunkName: "components/header-search" */).then(c => wrapFunctional(c.default || c))
export const HeaderStep = () => import('../../components/Header/HeaderStep.vue' /* webpackChunkName: "components/header-step" */).then(c => wrapFunctional(c.default || c))
export const HomeHeading = () => import('../../components/HomeHeading/HomeHeading.vue' /* webpackChunkName: "components/home-heading" */).then(c => wrapFunctional(c.default || c))
export const HomeHeadingBooksBanner = () => import('../../components/HomeHeading/HomeHeadingBooksBanner.vue' /* webpackChunkName: "components/home-heading-books-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeHeadingNewHeroBanner = () => import('../../components/HomeHeading/HomeHeadingNewHeroBanner.vue' /* webpackChunkName: "components/home-heading-new-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const NxBgPicture = () => import('../../components/Nx/NxBgPicture.vue' /* webpackChunkName: "components/nx-bg-picture" */).then(c => wrapFunctional(c.default || c))
export const NxBlurhashImg = () => import('../../components/Nx/NxBlurhashImg.vue' /* webpackChunkName: "components/nx-blurhash-img" */).then(c => wrapFunctional(c.default || c))
export const NxCheckbox = () => import('../../components/Nx/NxCheckbox.vue' /* webpackChunkName: "components/nx-checkbox" */).then(c => wrapFunctional(c.default || c))
export const NxErrors = () => import('../../components/Nx/NxErrors.vue' /* webpackChunkName: "components/nx-errors" */).then(c => wrapFunctional(c.default || c))
export const NxFooter = () => import('../../components/Nx/NxFooter.vue' /* webpackChunkName: "components/nx-footer" */).then(c => wrapFunctional(c.default || c))
export const NxHeader = () => import('../../components/Nx/NxHeader.vue' /* webpackChunkName: "components/nx-header" */).then(c => wrapFunctional(c.default || c))
export const NxImg = () => import('../../components/Nx/NxImg.vue' /* webpackChunkName: "components/nx-img" */).then(c => wrapFunctional(c.default || c))
export const NxInfoText = () => import('../../components/Nx/NxInfoText.vue' /* webpackChunkName: "components/nx-info-text" */).then(c => wrapFunctional(c.default || c))
export const NxInput = () => import('../../components/Nx/NxInput.vue' /* webpackChunkName: "components/nx-input" */).then(c => wrapFunctional(c.default || c))
export const NxLoader = () => import('../../components/Nx/NxLoader.vue' /* webpackChunkName: "components/nx-loader" */).then(c => wrapFunctional(c.default || c))
export const NxNarrowContainer = () => import('../../components/Nx/NxNarrowContainer.vue' /* webpackChunkName: "components/nx-narrow-container" */).then(c => wrapFunctional(c.default || c))
export const NxOffers = () => import('../../components/Nx/NxOffers.vue' /* webpackChunkName: "components/nx-offers" */).then(c => wrapFunctional(c.default || c))
export const NxPaymentLogos = () => import('../../components/Nx/NxPaymentLogos.vue' /* webpackChunkName: "components/nx-payment-logos" */).then(c => wrapFunctional(c.default || c))
export const NxTooltip = () => import('../../components/Nx/NxTooltip.vue' /* webpackChunkName: "components/nx-tooltip" */).then(c => wrapFunctional(c.default || c))
export const OffersFeaturesList = () => import('../../components/Offers/OffersFeaturesList.vue' /* webpackChunkName: "components/offers-features-list" */).then(c => wrapFunctional(c.default || c))
export const OffersTable = () => import('../../components/Offers/OffersTable.vue' /* webpackChunkName: "components/offers-table" */).then(c => wrapFunctional(c.default || c))
export const OffersTableFeatures = () => import('../../components/Offers/OffersTableFeatures.vue' /* webpackChunkName: "components/offers-table-features" */).then(c => wrapFunctional(c.default || c))
export const PartnerAccountBanner = () => import('../../components/Partner/PartnerAccountBanner.vue' /* webpackChunkName: "components/partner-account-banner" */).then(c => wrapFunctional(c.default || c))
export const PartnerDetails = () => import('../../components/Partner/PartnerDetails.vue' /* webpackChunkName: "components/partner-details" */).then(c => wrapFunctional(c.default || c))
export const PartnerForm = () => import('../../components/Partner/PartnerForm.vue' /* webpackChunkName: "components/partner-form" */).then(c => wrapFunctional(c.default || c))
export const PartnerList = () => import('../../components/Partner/PartnerList.vue' /* webpackChunkName: "components/partner-list" */).then(c => wrapFunctional(c.default || c))
export const PartnerSubscribe = () => import('../../components/Partner/PartnerSubscribe.vue' /* webpackChunkName: "components/partner-subscribe" */).then(c => wrapFunctional(c.default || c))
export const PaymentAdyenDropin = () => import('../../components/Payment/PaymentAdyenDropin.vue' /* webpackChunkName: "components/payment-adyen-dropin" */).then(c => wrapFunctional(c.default || c))
export const PaymentCardsIcons = () => import('../../components/Payment/PaymentCardsIcons.vue' /* webpackChunkName: "components/payment-cards-icons" */).then(c => wrapFunctional(c.default || c))
export const PaymentDimoco = () => import('../../components/Payment/PaymentDimoco.vue' /* webpackChunkName: "components/payment-dimoco" */).then(c => wrapFunctional(c.default || c))
export const PaymentDimocoTelcosLogo = () => import('../../components/Payment/PaymentDimocoTelcosLogo.vue' /* webpackChunkName: "components/payment-dimoco-telcos-logo" */).then(c => wrapFunctional(c.default || c))
export const ProfileAvatar = () => import('../../components/Profile/ProfileAvatar.vue' /* webpackChunkName: "components/profile-avatar" */).then(c => wrapFunctional(c.default || c))
export const ProfilesCreatedProfile = () => import('../../components/Profiles/ProfilesCreatedProfile.vue' /* webpackChunkName: "components/profiles-created-profile" */).then(c => wrapFunctional(c.default || c))
export const ProfilesEdit = () => import('../../components/Profiles/ProfilesEdit.vue' /* webpackChunkName: "components/profiles-edit" */).then(c => wrapFunctional(c.default || c))
export const ProfilesList = () => import('../../components/Profiles/ProfilesList.vue' /* webpackChunkName: "components/profiles-list" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionDetails = () => import('../../components/SubscriptionDetails/SubscriptionDetails.vue' /* webpackChunkName: "components/subscription-details" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionDetailsCancel = () => import('../../components/SubscriptionDetails/SubscriptionDetailsCancel.vue' /* webpackChunkName: "components/subscription-details-cancel" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionDetailsText = () => import('../../components/SubscriptionDetails/SubscriptionDetailsText.vue' /* webpackChunkName: "components/subscription-details-text" */).then(c => wrapFunctional(c.default || c))
export const MixinsEscapable = () => import('../../components/mixins/escapable.js' /* webpackChunkName: "components/mixins-escapable" */).then(c => wrapFunctional(c.default || c))
export const MixinsLocalized = () => import('../../components/mixins/localized.js' /* webpackChunkName: "components/mixins-localized" */).then(c => wrapFunctional(c.default || c))
export const CatalogLandingBrainStamp = () => import('../../components/Catalog/CatalogLanding/CatalogLandingBrainStamp.vue' /* webpackChunkName: "components/catalog-landing-brain-stamp" */).then(c => wrapFunctional(c.default || c))
export const CatalogLandingHero = () => import('../../components/Catalog/CatalogLanding/CatalogLandingHero.vue' /* webpackChunkName: "components/catalog-landing-hero" */).then(c => wrapFunctional(c.default || c))
export const CatalogLandingPhone = () => import('../../components/Catalog/CatalogLanding/CatalogLandingPhone.vue' /* webpackChunkName: "components/catalog-landing-phone" */).then(c => wrapFunctional(c.default || c))
export const NxLogo = () => import('../../components/Nx/NxLogo/NxLogo.vue' /* webpackChunkName: "components/nx-logo" */).then(c => wrapFunctional(c.default || c))
export const NxLogoAmex = () => import('../../components/Nx/NxLogo/NxLogoAmex.vue' /* webpackChunkName: "components/nx-logo-amex" */).then(c => wrapFunctional(c.default || c))
export const NxLogoCartebancaire = () => import('../../components/Nx/NxLogo/NxLogoCartebancaire.vue' /* webpackChunkName: "components/nx-logo-cartebancaire" */).then(c => wrapFunctional(c.default || c))
export const NxLogoForbrugsforeningen = () => import('../../components/Nx/NxLogo/NxLogoForbrugsforeningen.vue' /* webpackChunkName: "components/nx-logo-forbrugsforeningen" */).then(c => wrapFunctional(c.default || c))
export const NxLogoFreenet = () => import('../../components/Nx/NxLogo/NxLogoFreenet.vue' /* webpackChunkName: "components/nx-logo-freenet" */).then(c => wrapFunctional(c.default || c))
export const NxLogoIdeal = () => import('../../components/Nx/NxLogo/NxLogoIdeal.vue' /* webpackChunkName: "components/nx-logo-ideal" */).then(c => wrapFunctional(c.default || c))
export const NxLogoKlarna = () => import('../../components/Nx/NxLogo/NxLogoKlarna.vue' /* webpackChunkName: "components/nx-logo-klarna" */).then(c => wrapFunctional(c.default || c))
export const NxLogoMastercard = () => import('../../components/Nx/NxLogo/NxLogoMastercard.vue' /* webpackChunkName: "components/nx-logo-mastercard" */).then(c => wrapFunctional(c.default || c))
export const NxLogoNextory = () => import('../../components/Nx/NxLogo/NxLogoNextory.vue' /* webpackChunkName: "components/nx-logo-nextory" */).then(c => wrapFunctional(c.default || c))
export const NxLogoO2 = () => import('../../components/Nx/NxLogo/NxLogoO2.vue' /* webpackChunkName: "components/nx-logo-o2" */).then(c => wrapFunctional(c.default || c))
export const NxLogoOrange = () => import('../../components/Nx/NxLogo/NxLogoOrange.vue' /* webpackChunkName: "components/nx-logo-orange" */).then(c => wrapFunctional(c.default || c))
export const NxLogoPaypal = () => import('../../components/Nx/NxLogo/NxLogoPaypal.vue' /* webpackChunkName: "components/nx-logo-paypal" */).then(c => wrapFunctional(c.default || c))
export const NxLogoSofort = () => import('../../components/Nx/NxLogo/NxLogoSofort.vue' /* webpackChunkName: "components/nx-logo-sofort" */).then(c => wrapFunctional(c.default || c))
export const NxLogoStrex = () => import('../../components/Nx/NxLogo/NxLogoStrex.vue' /* webpackChunkName: "components/nx-logo-strex" */).then(c => wrapFunctional(c.default || c))
export const NxLogoTMobile = () => import('../../components/Nx/NxLogo/NxLogoTMobile.vue' /* webpackChunkName: "components/nx-logo-t-mobile" */).then(c => wrapFunctional(c.default || c))
export const NxLogoTrustly = () => import('../../components/Nx/NxLogo/NxLogoTrustly.vue' /* webpackChunkName: "components/nx-logo-trustly" */).then(c => wrapFunctional(c.default || c))
export const NxLogoVisa = () => import('../../components/Nx/NxLogo/NxLogoVisa.vue' /* webpackChunkName: "components/nx-logo-visa" */).then(c => wrapFunctional(c.default || c))
export const NxLogoVodafone = () => import('../../components/Nx/NxLogo/NxLogoVodafone.vue' /* webpackChunkName: "components/nx-logo-vodafone" */).then(c => wrapFunctional(c.default || c))
export const NxLogoZahl = () => import('../../components/Nx/NxLogo/NxLogoZahl.vue' /* webpackChunkName: "components/nx-logo-zahl" */).then(c => wrapFunctional(c.default || c))
export const PaymentFormDimoco = () => import('../../components/Payment/PaymentForm/PaymentFormDimoco.vue' /* webpackChunkName: "components/payment-form-dimoco" */).then(c => wrapFunctional(c.default || c))
export const PaymentFormDimocoLegals = () => import('../../components/Payment/PaymentForm/PaymentFormDimocoLegals.vue' /* webpackChunkName: "components/payment-form-dimoco-legals" */).then(c => wrapFunctional(c.default || c))
export const PaymentFormDimocoLinks = () => import('../../components/Payment/PaymentForm/PaymentFormDimocoLinks.vue' /* webpackChunkName: "components/payment-form-dimoco-links" */).then(c => wrapFunctional(c.default || c))
export const PaymentFormFreeTrialDisclaimer = () => import('../../components/Payment/PaymentForm/PaymentFormFreeTrialDisclaimer.vue' /* webpackChunkName: "components/payment-form-free-trial-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const PaymentFormIdeal = () => import('../../components/Payment/PaymentForm/PaymentFormIdeal.vue' /* webpackChunkName: "components/payment-form-ideal" */).then(c => wrapFunctional(c.default || c))
export const PaymentFormPaypal = () => import('../../components/Payment/PaymentForm/PaymentFormPaypal.vue' /* webpackChunkName: "components/payment-form-paypal" */).then(c => wrapFunctional(c.default || c))
export const PaymentFormSofort = () => import('../../components/Payment/PaymentForm/PaymentFormSofort.vue' /* webpackChunkName: "components/payment-form-sofort" */).then(c => wrapFunctional(c.default || c))
export const PaymentFormTrustly = () => import('../../components/Payment/PaymentForm/PaymentFormTrustly.vue' /* webpackChunkName: "components/payment-form-trustly" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
