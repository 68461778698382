import axios, { AxiosInstance } from 'axios'
import consola, { LogLevel } from 'consola'
import { RequestInterface } from './interfaces/request'
// @ts-ignore
import { version } from '~/package'

const logger = consola.withScope('Hummingbird (Nextory)')

export class Request implements RequestInterface {
  public readonly http: AxiosInstance
  private logLevel: LogLevel

  constructor(
    private origin: string, // eg. https://staging.nextory.app
    private baseURL: string,
    private countryCode: string | undefined = undefined, // Format: "SE"
    private locale: string | undefined = undefined // Format: "sv"
  ) {
    logger.debug('Hummingbird created (modern):', {
      version,
      baseURL,
      countryCode,
      locale,
    })
    this.logLevel = LogLevel.Info
    this.http = this.prepareHttpClient()
  }

  private prepareHttpClient() {
    let headers = {
      'Content-Type': 'application/json',
      'X-Application-Id': '203', // Web
      'X-Model': 'web', // TODO: Get User Agent from request
      'X-App-Version': version,
      'X-Country-Code': this.countryCode,
      'X-Locale': `${this.locale}_${this.countryCode}`, // Format locale_COUNTRY, e.g. sv_SE
    } as Record<string, string>

    if (process.server) {
      // Origin must be specified in SSR (but not in a browser - it'll add it itself)
      headers.Origin = this.origin
    }

    logger.debug('Hummingbird headers (modern):', headers)

    const http = axios.create({
      baseURL: this.baseURL,
      headers: {
        common: headers,
      },
    })

    // On error, log the response body (and not just the status code, as Axios does by default)
    http.interceptors.response.use(
      response => response,
      error => {
        logger.error(
          'Hummingbird request failed with code',
          error.response?.status,
          'and body:\n',
          error.response?.data
        )
        return Promise.reject(error)
      }
    )

    return http
  }

  private setHeader(header: string, value: string | null | undefined) {
    if (value) {
      logger.debug(`Setting ${header} to [${value}]`)
      this.http.defaults.headers.common[header] = value
    } else {
      logger.debug(`Removing ${header}`)
      delete this.http.defaults.headers.common[header]
    }
  }

  _setDeviceId(deviceId: string) {
    this.setHeader('X-Device-Id', deviceId)
  }

  _setCountry(country: string | undefined) {
    this.setHeader('X-Country-Code', country)
  }

  _setLogLevel(logLevel: LogLevel) {
    this.logLevel = logLevel
    logger.level = logLevel
  }

  _setAuthToken(authToken?: string) {
    this.setHeader('X-Login-Token', authToken)
  }
}
