
import ImgixClient from '@imgix/js-core'

export default {
  props: {
    mobileImg: {
      type: Object,
      default() {
        return { src: undefined, width: 450, height: 600 }
      },
    },
    tabletImg: {
      type: Object,
      default() {
        return { src: undefined, width: 768, height: 800 }
      },
    },
    desktopImg: {
      type: Object,
      default() {
        return { src: undefined, width: 1440, height: 900 }
      },
    },
    position: {
      type: String,
      default: 'top',
    },
  },

  data() {
    return {
      src: null,
      srcset: null,
    }
  },

  fetch() {
    // If we have an absolute URL, we won't be able to use the ImgixClient, so we'll just use the src as is.
    if (this.desktopImg.src.startsWith('http')) {
      this.src = this.desktopImg.src
      return
    }

    const ixClient = new ImgixClient({
      domain: 'nextory-assets.imgix.net',
    })

    const mobileSrcset = ixClient.buildSrcSet(
      this.mobileImg.src,
      {
        auto: 'format',
        q: 70,
        fit: 'crop',
        ar: `${this.mobileImg.width}:${this.mobileImg.height}`,
      },
      {
        minWidth: Math.min(this.mobileImg.width, 320),
        maxWidth: this.mobileImg.width,
      }
    )
    const tabletSrcset = ixClient.buildSrcSet(
      this.tabletImg.src,
      {
        auto: 'format',
        q: 75,
        fit: 'crop',
        ar: `${this.tabletImg.width}:${this.tabletImg.height}`,
      },
      {
        minWidth: Math.min(this.tabletImg.width, this.mobileImg.width + 50),
        maxWidth: this.tabletImg.width,
      }
    )
    const desktopSrcset = ixClient.buildSrcSet(
      this.desktopImg.src,
      {
        auto: 'format',
        q: 80,
        fit: 'crop',
        ar: `${this.desktopImg.width}:${this.desktopImg.height}`,
      },
      {
        minWidth: Math.min(this.desktopImg.width, this.tabletImg.width + 50),
        maxWidth: this.desktopImg.width,
      }
    )
    this.srcset = [mobileSrcset, tabletSrcset, desktopSrcset]
      .join(',\n')
      .split('\n')
      .map(line => {
        // Split the line into src and width (example: "https://example.com/img.jpg 500w")
        let [src, width] = line.split(' ')

        width = width.replace(/w,?$/, 'px')
        width = `(max-width: ${width})`

        return {
          src,
          width,
        }
      })

    this.src = ixClient.buildURL(
      this.desktopImg.src,
      {
        auto: 'format',
        q: 80,
        w: this.desktopImg.width,
        h: this.desktopImg.height,
        fit: 'crop',
      },
      this.minMaxWidth
    )
  },
}
