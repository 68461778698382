/* eslint-disable */
/* This is a generated file. */
import loader from '~/i18n/loader'
export default loader('en', {
  "404.body": "We couldn’t find the page you're looking for, but we found this cat reading a book.",
  "404.button._browse_books": "Browse books",
  "404.cta": "Go back to the home page",
  "404.title": "The page doesn’t exist",
  "about.our_story.body": "Nextory was founded in Stockholm in 2015 with the goal of making the world's books available to more people and challenging what it means to \"read a book\". In recent years, technology has changed the world we live in and our vision is therefore more relevant than ever: We want to increase reading by creating richer reading experiences. Our goal is for you to read and listen to stories instead of wasting time surfing or scrolling around among meaningless images.\n\nToday, Nextory is one of the Nordic region's largest subscription services for audio and e-books. The app is also available in other European markets. With Nextory, it's easy to find the right books - on your phone or tablet. There are hundreds of thousands of titles to choose from.\n\nWhether you exercise, cook, sit in the car or just relax, your favorite books are always close at hand. Choose what you want to read and listen to or let yourself be inspired by our book lists.",
  "about.our_story.body_2024": "Once upon a time…\n\n…there were two kids, Shadi Bitar and Ninos Malki whose biggest passion was reading. They loved reading all kinds of books, and most importantly, they loved the feeling of having access to information. Unfortunately, they had limited resources, which would later become the starting point for the success story: Nextory. Once they as kids left Syria for Sweden, books became of even greater importance in their lives. They wanted to share their passion and make people fall in love with reading by creating a better reading and listening experience.\n\nNextory, founded in 2015 in Stockholm, is driven by a mission to make all the books in the world accessible to everyone. The company continues to grow, and today it stands tall as one of the largest streaming services for audiobooks and e-books in Europe.\n\nWith Nextory, the world of books is yours to explore, with a collection that spans hundreds of thousands of titles, catering to every interest and curiosity, all just a tap away on your phone or tablet. \n\n**Postal and visiting address:**  \nNextory AB  \nNorrtullsgatan 6  \n113 29 Stockholm  \nSweden\n\n**Contact:**  \npress@nextory.com\n\n**Organization number:**  \n556708-4149",
  "about_page.title_other_markets": "Visit our other markets",
  "app.mypages.account.edit_sub.error.broken_token.body": "To change your subscription, log in on the Nextory website and go to My pages.",
  "app.mypages.account.edit_sub.error.broken_token.title": "It looks like this link isn't working any longer",
  "book_screen.meta.price.legal_disclaimer": "Consultation of this title is included in your subscription. For your information, the rental price set by the publisher is {priceTotal} (i.e. {pricePerPage} per page read).",
  "bookpage.hero.logged_out.title": "Read and listen \nfree for {trialDays} days",
  "campaign.collect_points.funnel.error.user_illegible": "You’re not able to use this campaign.",
  "campaign.collect_points.funnel.error.wrong_identifyer": "This doesn’t look right. Try again.",
  "campaign.collect_points.funnel.logged_out.body": "Enter your password to start collecting {unit} monthly.",
  "campaign.collect_points.funnel.logged_out.success.body": "You can manage your settings in My pages.",
  "campaign.collect_points.funnel.logged_out.success.title": "Enjoy your {unit}!",
  "campaign.collect_points.funnel.new_user.title": "Create an account to continue",
  "campaign.collect_points.funnel.offer": "Collect from {amount} {unit} per month",
  "campaign.collect_points.funnel.offer.kjell": "Earn a discount at Kjell & Company every three months",
  "campaign.collect_points.funnel.payment": "You’ll collect {amount} {unit} per month.",
  "campaign.collect_points.funnel.sub_picker.points_amount": "Coop-points you’ll collect monthly",
  "campaign.collect_points.funnel.title": "Welcome back!",
  "campaign.regfunnel.payment.summary.free_days_and_cancel_date": "{price} per month starting {date} – cancel before and you’ll pay nothing.",
  "cancellation.funnel.success.title": "Your subscription has been canceled",
  "cancellation_flow.cancelled_subscription.winback.action.use_offer_now": "I want to use the offer directly",
  "cancellation_flow.cancelled_subscription.winback.button.copy_code": "Copy code",
  "cancellation_flow.cancelled_subscription.winback.card.body": "Enter this code when you want to start reading again to get your first {trialDays} days for free.",
  "cancellation_flow.cancelled_subscription.winback.card.title": "We miss you already!",
  "cancellation_funnel.action_go_back": "← Back to My pages",
  "cancellation_funnel.active_user.microcopy_date": "You’ll be able to read and listen until {date}.",
  "cancellation_funnel.cancel_trial.cancel.card.body": "Hope to see you again soon! Remember that you can’t read or listen any longer after you cancel your free trial.",
  "cancellation_funnel.cancel_trial.card.title": "Cancel free trial",
  "cancellation_funnel.canceled_subscription.success.title": "Your subscription has been cancelled",
  "cancellation_funnel.disclaimer.body": "When you cancel your subscription your free trial will also be canceled. You won’t be able to read or listen to books any longer.",
  "cancellation_funnel.disclaimer.error_box_not_ticked": "Tick the box to continue.",
  "cancellation_funnel.disclaimer_checkbox": "I understand.",
  "cancellation_funnel.downgrade.card.button": "Change to Silver",
  "cancellation_funnel.downgrade.title": "Not reading enough? {name} is perfect for when you read a little less.",
  "cancellation_funnel.extend_trial.extend.card.body": "If you haven’t found the time to read or listen yet you can extend your free trial with another {days} days.",
  "cancellation_funnel.extend_trial.extend.card.button": "Extend free trial",
  "cancellation_funnel.extend_trial.extend.card.title": "Extend your free trial",
  "cancellation_funnel.extend_trial.headline": "Haven’t found the time to read or listen?",
  "cancellation_funnel.reason1": "I read in intervals and will be back later",
  "cancellation_funnel.reason2": "The app is difficult to use",
  "cancellation_funnel.reason3": "I’m using a different service",
  "cancellation_funnel.reason4": "It’s too expensive",
  "cancellation_funnel.reason5": "The subscription doesn’t suit me",
  "cancellation_funnel.reason6": "I’m not able to read on the device I want",
  "cancellation_funnel.reason7": "The selection doesn’t live up to my expectations",
  "cancellation_funnel.reason8": "Other",
  "cancellation_funnel.revert_cancel.cancel.card.body": "We’ll keep your library just like you left it so you can come back and start reading and listening any time you like.",
  "cancellation_funnel.revert_cancel.card.button": "Stay another month",
  "cancellation_funnel.revert_cancel.extend.success.body": "You can read and listen for free until {date}.",
  "cancellation_funnel.revert_cancel.headline": "Before you go – We’ve made it easier for you to stay.",
  "cancellation_funnel.revert_cancel.stay.card.body": "Stick around and pay {price} for your next month. You’ll be charged full price again starting {date}.",
  "cancellation_funnel.revert_cancel.stay.card.title": "Stay another month for {price}",
  "cancellation_funnel.revert_cancel.stay.success.body": "You’ll be charged {discountAmount} on your next payment date on {discountDate}. Starting  {renewalDate} you’ll be charged the full price of {renewalAmount} monthly.",
  "cancellation_funnel.revert_cancel.success.body": "Download the app to read and listen. You’ll be logged in automatically.",
  "cancellation_funnel.revert_cancel.success.title": "We’re happy you’re staying!",
  "cancellation_funnel.success.body": "We hope to see you again soon!",
  "cancellation_funnel.title": "Why are you leaving Nextory?",
  "catalog.my_account": "My account",
  "catalog.my_booklists": "My books",
  "catalog.pagination": "Page {current} of {last}",
  "catalog.sign_in": "Log in",
  "catalog.sign_up": "Create an account",
  "catalogue.audiobooks.hero.body": "Find your favorites among hundreds of thousands of e-books and audiobooks. Starting at {price} per month. Cancel any time.",
  "catalogue.audiobooks.hero.title": "Listen, it’s all\nabout audiobooks",
  "catalogue.book.author": "Author:",
  "catalogue.book.comment.anonymous": "Anonymous",
  "catalogue.book.cover_warning": "Some covers are only visible when logged in",
  "catalogue.book.description.hide": "Hide",
  "catalogue.book.description.show": "Show more",
  "catalogue.book.duration": "Duration:",
  "catalogue.book.format_audiobook": "audiobook",
  "catalogue.book.format_audiobook_ebook": "audiobook & e-book",
  "catalogue.book.format_ebook": "E-book",
  "catalogue.book.info.isbn": "ISBN: {code}",
  "catalogue.book.info.language": "Language: {language}",
  "catalogue.book.info.published": "Published: {date}",
  "catalogue.book.info.publisher": "Publisher: {name}",
  "catalogue.book.narrator": "Narrator:",
  "catalogue.book.ratings": "1 rating|{n} ratings",
  "catalogue.book.serie": "Series:",
  "catalogue.book.write_review": "To write a review you need to download the app",
  "catalogue.books_categories.hero.description": "Discover a world of fascinating reads and gripping tales across various genres, perfect for everyone's taste.",
  "catalogue.ebooks.hero.title": "A good old\nfashioned e-book",
  "catalogue.footer.body": "Have a look at the selection.",
  "catalogue.footer.button": "See the selection",
  "catalogue.footer.title": "Want more?",
  "catalogue.landing.phone.alt": "Open \"{book}\" by {author}",
  "catalogue.magazines.hero.body": "Find your favorites among hundreds of thousands of magazines, e-books and audiobooks. Starting at {price} per month. Cancel any time.",
  "catalogue.magazines.hero.title": "Magazines in abundance",
  "catalogue.magazines_categories.hero.description": "Discover your new favorite magazine! Browse various genres catering to all interests.",
  "catalogue.pagination.last_page": "All books have been displayed.",
  "catalogue.pagination.last_page_author": "You've displayed all the books by {author}",
  "catalogue.pagination.last_page_category": "You've displayed all the books in the category {category}",
  "catalogue.pagination.last_page_comments": "No more comments",
  "catalogue.pagination.last_page_narrator": "You've displayed all the books narrated by {narrator}",
  "catalogue.pagination.last_page_series": "You've displayed all the books in the series {series}",
  "catalogue.pagination.show_more_books": "See more",
  "catalogue.pagination.show_more_comments": "Show more",
  "catalogue.search.input_label": "Search",
  "catalogue.search.no_results": "We couldn't find anything. Try {action} for something else or have a look at our suggestions.",
  "catalogue.search.no_results.cta": "searching",
  "catalogue.search.reset": "Reset",
  "catalogue.search.results": "{n} result|{n} results",
  "catalogue.search.suggestions": "Couldn't find what you were looking for? Have a look at our other suggestions below.",
  "catalogue.usp1.audiobook.body": "Listen to your favorite books while commuting, working out, or doing household chores. With Nextory, you can indulge your literary cravings whenever and wherever you want.",
  "catalogue.usp1.audiobook.title": "No more boring chores",
  "catalogue.usp1.ebook.body": "No more schlepping heavy books! Just endless reading adventures at your fingertips – wherever and whenever. Ready to embark on a literary journey?",
  "catalogue.usp1.ebook.title": "A library in your pocket",
  "catalogue.usp2.body": "From occasional readers to avid book lovers – we’ve got subscriptions that fit everyone. We also have a wide selection of children’s books for kids of all ages.",
  "catalogue.usp2.title": "Tailored for you",
  "catalogue.usp3.body": "Did you know that you can reduce stress levels by reading? Research shows that as little as 10 minutes of reading can make you more relaxed.",
  "catalogue.usp3.title": "Enjoy a calmer mind",
  "change_subscription.indicatior_current_subscription": "Current subscription",
  "download_app.body": "To start reading and listening to books, you need to download the app. The app is available for iOS and Android.",
  "download_app.title": "Download the app",
  "epage.pick_subscription.silver.usp_max_hours": "Read and listen up to 30 hours per month",
  "example_key_name": "Read & Listen. Unlimited. ",
  "features.ID": "ID",
  "features.available": "Available",
  "features.date_of_transaction": "Date of transaction",
  "features.notAvailable": "Not available",
  "features.price": "Price",
  "features.quantity": "Quantity",
  "features.subscription": "Subscription",
  "footer.accessibility": "Accessibility",
  "footer.accessibility_not_accessible": "Accessibility: not accessible",
  "footer.cookies": "Cookies",
  "footer.copyright": "Copyright © {year} Nextory AB",
  "footer.imprint": "Imprint",
  "footer.menu_become_partner": "Partner with us",
  "footer.menu_becomeareader": "Become a reader",
  "footer.menu_career": "Career",
  "footer.menu_career.url": "https://career.nextory.com/",
  "footer.menu_investor": "Investor relations",
  "footer.menu_our_story": "Our story",
  "footer.menu_press": "Press",
  "footer.menu_press.url": "https://www.mynewsdesk.com/nextory",
  "footer.menu_sell": "Sell via Nextory",
  "footer.privacy": "Privacy Policy",
  "footer.terms": "Terms",
  "footer.title_about_us": "About us",
  "footer.title_start_reading": "Start reading",
  "fr.footer.author_login": "Content partner access",
  "fr.footer.content_partner": "Become a content partner",
  "fr.footer.content_partner.url": "https://docs.google.com/forms/d/e/1FAIpQLSfyIzLF_jpo1cF7MBIWt25PckTsgwNsaHEIjnexSxjrCx5LoA/viewform",
  "fr.footer.pro_offers": "Offers for professionals",
  "fr.footer.pro_offers.url": "https://nextory.com/fr/collab/partner/nx-pass/",
  "fr.footer.publisher_login": "Content distributor access",
  "fr.footer.sfr": "Nextory One",
  "fr.footer.title": "For professionals",
  "fr.offer.global_usp_1_screen": "1 screen",
  "fr.offer.global_usp_2_screen": "2 screens",
  "fr.offer.global_usp_4_screen": "4 screens",
  "fr.offer.global_usp_all_in_one_app": "All your reading in one app",
  "giftcard.amount1.microcopy": "Enough for 1 month of Gold",
  "giftcard.amount2.microcopy": "Enough for 3 months of Gold",
  "giftcard.amount3.microcopy": "Enough for 6 months of Gold",
  "giftcard.amount4.microcopy": "Enough for 12 months of Gold",
  "giftcard.amount_2": "507 kr",
  "giftcard.amount_3": "860 kr",
  "giftcard.amount_4": "1 720 kr",
  "giftcard.body": "The recipient chooses which subscription they want when they redeem the gift card on the website.",
  "giftcard.discount.microcopy": "15% discount",
  "giftcard.email_giftcard.body": "Enter the recipient’s details.",
  "giftcard.multi_purchase": "Do you want to buy more than 10 gift cards? Reach out via partner@nextory.com",
  "giftcard.payment.body": "You can enter the recipients details in the next step. The gift card can be printed or sent via email.",
  "giftcard.payment.button": "Make payment",
  "giftcard.payment.email.label": "Your email address",
  "giftcard.sent.success.button": "Buy another one",
  "giftcard.sent.success.title": "The gift card has been sent!",
  "giftcard.subheader": "Select an amount",
  "giftcard.success.body": "Thank you for buying a gift card! You'll receive a confirmation via email.",
  "giftcard.success.button.email": "Send as email",
  "giftcard.success.button.print": "Print",
  "giftcard.success.subheader": "How would you like to gift it?",
  "giftcard.success.title": "Done!",
  "giftcard.title": "Why give someone just one book when you can give them 350,000?",
  "global.accessibility.nav.breadcrumb": "Breadcrumb",
  "global.accessibility.nav.footer": "Footer Navigation",
  "global.accessibility.nav.footer.legals": "Nextory legal links",
  "global.accessibility.nav.footer.networks": "Nextory social networks",
  "global.accessibility.nav.footer.pro": "Footer Navigation for Professionals",
  "global.accessibility.nav.main": "Main",
  "global.action.back_to_my_pages": "← Back to My pages",
  "global.action.maybe_later": "Maybe later",
  "global.action_go_back": "Go back",
  "global.android.download": "Get it on",
  "global.button.save": "Save",
  "global.button_add_profile": "Add profile",
  "global.button_back": "Back",
  "global.button_cancel": "Cancel",
  "global.button_close": "Close",
  "global.button_continue": "Continue",
  "global.button_create_profile": "Create profile",
  "global.button_delete": "Remove",
  "global.button_external_login": "Log in with external account",
  "global.button_finish": "Finish",
  "global.button_login": "Log in",
  "global.button_login_apple": "Log in with Apple",
  "global.button_login_fb": "Log in with Facebook",
  "global.button_login_google": "Log in with Google",
  "global.button_login_partner": "Log in with {partner}",
  "global.button_logout": "Log out",
  "global.button_send": "Send",
  "global.button_skip": "Skip",
  "global.button_try": "Try {name}",
  "global.button_try_family": "Try Family",
  "global.button_try_free": "Try {trialDays} days free",
  "global.button_try_free_with_unit": "Try {period} {periodUnit} free",
  "global.button_try_gold": "Try Gold",
  "global.button_try_silver": "Try Silver",
  "global.error.title": "Something went wrong. Try again.",
  "global.error.wrong_email": "This email doesn’t look right. Try again.",
  "global.error.wrong_password": "Double-check that you entered the right password.",
  "global.error_incorrect_password_length": "The password needs to consist of 6-60 characters.",
  "global.freetrial": "{trialDays} days for free.",
  "global.ios.download": "Download on the",
  "global.label.bbl": "Membership card number (9 numbers)",
  "global.label.code": "Code",
  "global.label.klm": "Flying blue number",
  "global.label.membership_number": "Membership number",
  "global.label.norwegian": "Reward Number",
  "global.label.ssn": "Social security number",
  "global.label_child_profile": "Child profile",
  "global.link_faq": "Help and contact",
  "global.link_faq.url": "https://support.nextory.se/hc/en-us",
  "global.microcopy.cancellation": "Cancellation",
  "global.microcopy.per_month": "/month",
  "global.microcopy.withdraw_right": "Right of Withdrawal",
  "global.microcopy_cancel_anytime": "Cancel anytime",
  "global.microcopy_pp": "Privacy Policy",
  "global.microcopy_profiles": "Profiles",
  "global.microcopy_tc": "Terms of Use",
  "global.microcopy_tc_pp": "By continuing, you accept Nextory's {term} and {privacy}",
  "global.number_1": "1",
  "global.number_2": "2",
  "global.number_3": "3",
  "global.number_4": "4",
  "global.payment_badge.secure": "Secure payments",
  "global.payment_method_card": "Card",
  "global.payment_method_dimoco": "DIMOCO",
  "global.payment_method_dimoco.strex": "Pay with your phone bill",
  "global.payment_method_fortumo": "Swisscom",
  "global.payment_method_giftcard": "Gift card",
  "global.payment_method_ideal": "iDEAL",
  "global.payment_method_other": "Other payment methods",
  "global.payment_method_pay_later_klarna": "Pay later with Klarna",
  "global.payment_method_pay_now_klarna": "Pay now with Klarna",
  "global.payment_method_paypal": "PayPal",
  "global.payment_method_sofort": "Sofort",
  "global.payment_method_trustly": "Trustly",
  "global.period_unit.day": "day|days",
  "global.period_unit.month": "month|months",
  "global.period_unit.week": "week|weeks",
  "global.period_unit.year": "year|years",
  "global.price": "€ 9.99",
  "global.profile": "Profile",
  "global.social_network.facebook.url": "https://www.facebook.com/Nextory/",
  "global.social_network.instagram.url": "https://www.instagram.com/nextory/",
  "global.subscription_usp.1_simultanoeus_profile": "1 profile can use the app at a time",
  "global.subscription_usp.family_many_profiles": "2-4 profiles can use the app simultaneously",
  "global.subscription_usp.unlimited": "Read and listen as much as you like",
  "global.unit.points": "points",
  "hero.logged_in_user.user_name": "David",
  "homepage.activity_slider.card_1.body": "A captivating audiobook will surely make cleaning more fun.",
  "homepage.activity_slider.card_1.title": "Clean",
  "homepage.activity_slider.card_2.body": "With our sleep stories you will fall asleep in no time.",
  "homepage.activity_slider.card_2.title": "Sleep tight",
  "homepage.activity_slider.card_3.body": "Upgrade your workout routine and try running to the beat of a thriller.",
  "homepage.activity_slider.card_3.title": "Work out",
  "homepage.activity_slider.card_4.body": "Connect your device and take a trip with your favorite author.",
  "homepage.activity_slider.card_4.title": "Go on a road trip",
  "homepage.activity_slider.card_5.body": "Pause your to-do list and let your imagination run wild.",
  "homepage.activity_slider.card_5.title": "Daydream",
  "homepage.activity_slider.card_6.body": "Learn anything and everything about your favorite subject.",
  "homepage.activity_slider.card_6.title": "Deep dive",
  "homepage.activity_slider.title": "Us + you = a more enjoyable everyday life",
  "homepage.banner.movistar.button": "Click here!",
  "homepage.banner.movistar.logo_alt": "Movistar's logo",
  "homepage.banner.movistar.title": "Are you a Movistar customer?",
  "homepage.banner.stories_of_iran.read_more": "Read more",
  "homepage.banner.stories_of_iran.text": "{readmore} about Narges Mohammadi, author of the book {book} and winner of the Nobel Peace Prize.",
  "homepage.banner.stories_of_iran.white_torture": "\"White Torture\"",
  "homepage.divider.alt": "Quote:",
  "homepage.divider.body": "→ Don’t follow the feed, feed your brain",
  "homepage.divider.title": "Scrolling quotes",
  "homepage.faq.answer_1": "Nextory is a streaming service with hundreds of thousands of audiobooks and e-books. The number of hours you can read and listen depends on what subscription you choose. Regardless, you have access to all our books. Since you can read and listen offline Nextory is also the perfect travel companion.\n\nTo read and listen to books, you first need to download the Nextory app to your smartphone or tablet. The app can be used on both iOS and Android. New customers who sign up always get a free trial period. At the end of the trial period, the subscription will be converted to a regular price and you will be charged monthly. Of course, you can cancel your subscription before the end of the trial period to avoid being charged. In this case, you must cancel your subscription no later than the day before the end of the trial period.",
  "homepage.faq.answer_2": "Start by registering your account on the Nextory website. Then download the app in the App Store or Google Play to your mobile phone or tablet. We support both iOS and Android. The last step is to log in to the app and start your first book!\n\nAs long as your subscription is active, you have access to Nextory's books. The app is full of inspiring book lists and personalized recommendations. Of course, you can also search for titles or favorite authors.\n\nWhen you read or listen to a book, it is downloaded to your device. Therefore, you can always read and listen to activated books even when you are not connected. After finishing the book, you can easily remove it from downloads so that the book no longer takes up space on your device.",
  "homepage.faq.answer_3": "During the trial period, you can read and listen with Nextory for free. Your first trial day is the same day you create your account.\n\nWhen you start a trial, you register your payment details. This is so you can read and listen without interruption if you want to continue using Nextory after the trial period. When the trial ends, you will be charged according to the subscription you chose when you created your account.\n\nIf you do not want to continue to use Nextory and want to avoid a charge, you need to cancel the subscription no later than the last trial day by logging in to My pages. If you choose to cancel your subscription via customer service, you must contact us no later than two days before your last trial day.\n\nYou can always see the date of the next charge under My pages. The last trial day is the day before the billing.\n\nIf you choose to cancel the subscription before your trial period ends, your subscription will stop working immediately. But remember that you are always welcome back!",
  "homepage.faq.answer_4": "When you start a trial period, you also register your payment details. This is so that you can continue with your books without interruption if you choose to use the service after the trial period has ended.\n\nAfter the trial period, you will be charged according to the subscription you have chosen if you have not canceled beforehand.",
  "homepage.faq.question_1": "What is Nextory?",
  "homepage.faq.question_2": "How does the service work?",
  "homepage.faq.question_3": "How does the trial period work?",
  "homepage.faq.question_4": "Why do I need to fill in payment details?",
  "homepage.faq.title": "Frequently asked questions",
  "homepage.hero.body": "Find your favorites among hundreds of thousands of audiobooks and e-books. Let the books become the family's own success story.",
  "homepage.hero.body.ab_test_new_hero": "We have hundreds of thousands of audiobooks and e-books. Find your favorites starting at {price} per month.",
  "homepage.hero.body.magazine_market": "Find your favorites among hundreds of thousands of audiobooks, e-books and magazines. Starting at {price} per month.",
  "homepage.hero.body.magazine_market_copy": "Find your favorites among hundreds of thousands of audiobooks, e-books and magazines. Starting at {price} per month.",
  "homepage.hero.body.omni": "Right now you get the news service Omni Mer for free when you start an Unlimited-subscription. Read and listen to audiobooks, e-books and magazines. Starting at {price} per month.",
  "homepage.hero.body.summer_campaign_2024": "The door to a world where all is possible. Let audiobooks be your family's success story. Escape into our hundreds of thousands of titles.",
  "homepage.hero.body_copy": "Find your favorites among hundreds of thousands of audiobooks and e-books. Let the books become the family's own success story.",
  "homepage.hero.button.ab_test_new_hero": "Try free now",
  "homepage.hero.button.without_days": "Try free now",
  "homepage.hero.img_desktop": "nx-images/home/hero/global/hero-be-desktop.jpg",
  "homepage.hero.img_mobile": "nx-images/home/hero/global/hero-offer-mobile.jpeg",
  "homepage.hero.img_tablet": "nx-images/home/hero/global/hero-be-tablet.jpg",
  "homepage.hero.title": "Audiobooks for the whole family",
  "homepage.hero.title.ab_test_new_hero": "Listen to this!",
  "homepage.hero.title.omni": "Get the news service Omni Mer free of charge",
  "homepage.hero.title.summer_campaign_2024": "Put family life’s \n[chaos on pause]",
  "homepage.hero.title_copy": "Audiobooks for the whole family",
  "homepage.menu.language_picker": "Language",
  "homepage.menu.language_picker.en": "EN",
  "homepage.menu.language_picker.fr": "FR",
  "homepage.menu_books": "Books",
  "homepage.menu_buy_giftcard": "Buy gift card",
  "homepage.menu_campaign_code": "Use campaign code",
  "homepage.menu_categories": "Categories",
  "homepage.menu_download_app": "Download app",
  "homepage.menu_help": "Help",
  "homepage.menu_home": "Home",
  "homepage.menu_magazines": "Magazines",
  "homepage.menu_redeem_giftcard": "Redeem gift card",
  "homepage.more_about.body.usp_books_for_family": "Increase the whole family’s reading time by challenging each other in a book duel! Set common goals and experience the magic of books together from our extensive collection of children's books.",
  "homepage.more_about.body.usp_life_better_w_books": "We want to enrich your life with stories from all over the world, no matter who you are. At Nextory you can easily dive into a sea of different audiobooks and e-books.",
  "homepage.more_about.body.usp_read_or_listen": "With the Nextory app, you can listen and read on your phone or tablet wherever you are, even offline. You just create an account and download the app. It’s as easy as that!",
  "homepage.more_about.body.usp_read_or_listen.magazine_market": "With the Nextory app, you can listen and read on your phone or tablet wherever you are, even offline. You just create an account and download the app. It’s as easy as that!",
  "homepage.more_about.img_1.alt": "Tablet image",
  "homepage.more_about.img_1.src": "nx-images/home/more-about-nx/be/1-BE.jpg",
  "homepage.more_about.img_2.alt": "Book images",
  "homepage.more_about.img_2.src": "nx-images/home/more-about-nx/be/2-BE.jpg",
  "homepage.more_about.img_3.alt": "A woman listening to audiobooks",
  "homepage.more_about.img_3.src": "nx-images/home/more-about-nx/be/3-BE.jpg",
  "homepage.more_about.title": "This is Nextory",
  "homepage.more_about.title.usp_books_for_family": "Share the love of reading with your family",
  "homepage.more_about.title.usp_life_better_w_books": "Get access to hundreds of thousands of books",
  "homepage.more_about.title.usp_read_or_listen": "As easy as a-b-c",
  "homepage.parallax.body": "Get the Nextory app on your smartphone or tablet. And take your favorite stories with you — on every adventure",
  "homepage.parallax.title": "Read and listen on the go",
  "homepage.phone.img_phone_cover.alt": "Book illustrations",
  "homepage.phone.img_phone_cover.src": "nx-images/home/phone/be/phonebookcovers_BE.jpg",
  "homepage.pick_subscription.body_top_of_page": "All subscriptions have a {trialDays}-day free trial. Cancel whenever you like.",
  "homepage.pick_subscription.body_top_of_page.time_limit": "All subscriptions have a {period} {periodUnit} free trial with {timeLimitHours} hours. Cancel whenever you like.",
  "homepage.pick_subscription.body_top_of_page.unlimited": "All subscriptions have a {trialDays}-day free trial. You can read and listen as much as you like and cancel whenever you want.",
  "homepage.pick_subscription.days_trial": "{days} days free",
  "homepage.pick_subscription.family_microcopy": " ",
  "homepage.pick_subscription.family_title": "Family",
  "homepage.pick_subscription.features.FAMILY": "Read and listen as much as you like\n{profiles} profiles\n{max-profile-login} profiles can use the app simultaneously",
  "homepage.pick_subscription.features.GOLD": "Read and listen as much as you like\n{profiles} profiles\n{max-profile-login} profile can use the app at a time",
  "homepage.pick_subscription.features.SILVER": "Read and listen up to {hours} hours per month\n{profiles} profiles\n{max-profile-login} profile can use the app at a time",
  "homepage.pick_subscription.features.audio_series": "Unlimited exclusive audio series and podcasts",
  "homepage.pick_subscription.features.ebook": "Unlimited books and comics",
  "homepage.pick_subscription.features.hours.limited": "Read and listen up to {n} hours per month",
  "homepage.pick_subscription.features.hours.unlimited": "Read and listen as much as you like",
  "homepage.pick_subscription.features.max_profiles_login": "{n} profile can use the app at a time|{n} profiles can use the app simultaneously",
  "homepage.pick_subscription.features.offline": "Offline reading",
  "homepage.pick_subscription.features.press_magazine": "Unlimited magazines and daily newspapers",
  "homepage.pick_subscription.features.profiles": "{n} profile|{n} profiles",
  "homepage.pick_subscription.gold_microcopy": " ",
  "homepage.pick_subscription.gold_title": "Gold",
  "homepage.pick_subscription.micro_copy.BASIC": "For when you read less",
  "homepage.pick_subscription.micro_copy.FAMILY": "Read together",
  "homepage.pick_subscription.micro_copy.GOLD": "For when you read more",
  "homepage.pick_subscription.micro_copy.PREMIUM": "For when you read more",
  "homepage.pick_subscription.micro_copy.SILVER": "For when you read less",
  "homepage.pick_subscription.micro_copy.UNLIMITED": "Read together",
  "homepage.pick_subscription.micro_copy.UNLIMITED0": "For when you read more",
  "homepage.pick_subscription.price": "Then {price}/month",
  "homepage.pick_subscription.price.unlimited": "Then from {price}/month",
  "homepage.pick_subscription.silver_microcopy": " ",
  "homepage.pick_subscription.silver_title": "Silver",
  "homepage.pick_subscription.title_top_of_page": "Pick a subscription",
  "homepage.pick_subscription.trial_period": "{period} {periodUnit} free",
  "homepage.pick_subscription.unlimited.button": "See options",
  "homepage.popular_books.body": "We’ve got titles for all ages. Everything from thrillers to feel-goods and bedtime stories. Have a look at our lists to find your next story.",
  "homepage.popular_books.title": "Popular books",
  "homepage.popular_books.title_most_read_books_list": "Most-read books",
  "homepage.popular_books.title_popular_books_list": "This week’s most popular books",
  "homepage.review.body": "More than 52,000 people have given the Nextory app top rating!",
  "homepage.review.denmark_1.body": "I think they have some really good books and everything works for me, so great app.",
  "homepage.review.denmark_1.platform": "iOS",
  "homepage.review.denmark_1.stars": "5",
  "homepage.review.denmark_1.username": "Mikkelrj",
  "homepage.review.denmark_2.body": "The perfect app for parents of young kids. With Nextory I can listen to many exciting books, and still be able to handle all sorts of practical things.",
  "homepage.review.denmark_2.platform": "iOS",
  "homepage.review.denmark_2.stars": "5",
  "homepage.review.denmark_2.username": "N.K.",
  "homepage.review.denmark_3.body": "Really good e-book and audiobook service!",
  "homepage.review.denmark_3.platform": "Google Play",
  "homepage.review.denmark_3.stars": "5",
  "homepage.review.denmark_3.username": "Michell H.",
  "homepage.review.denmark_4.body": "Easy and well-functioning app!",
  "homepage.review.denmark_4.platform": "Google Play",
  "homepage.review.denmark_4.stars": "5",
  "homepage.review.denmark_4.username": "Liv T.",
  "homepage.review.finland_1.body": "This is a really nice app! You can listen to your favorite books and even read them! I recommend this app!",
  "homepage.review.finland_1.platform": "iOS",
  "homepage.review.finland_1.stars": "5",
  "homepage.review.finland_1.username": "Kassukoira",
  "homepage.review.finland_2.body": "Absolutely wonderful app!",
  "homepage.review.finland_2.platform": "Google Play",
  "homepage.review.finland_2.stars": "5",
  "homepage.review.finland_2.username": "Aino L.",
  "homepage.review.finland_3.body": "Nextory is a really good audiobook service where you can be enchanted with new worlds through books, happiness!",
  "homepage.review.finland_3.platform": "iOS",
  "homepage.review.finland_3.stars": "5",
  "homepage.review.finland_3.username": "Ansku B.",
  "homepage.review.finland_4.body": "I love Nextory, the best audiobook service!",
  "homepage.review.finland_4.platform": "iOS",
  "homepage.review.finland_4.stars": "5",
  "homepage.review.finland_4.username": "Nextykkä",
  "homepage.review.france_1.body": "The Nextory application has a good catalog, diverse and varied. Access to magazines is a real plus! For my part, I can’t do without it anymore!",
  "homepage.review.france_1.platform": "iOS",
  "homepage.review.france_1.stars": "5",
  "homepage.review.france_1.username": "Schmounou",
  "homepage.review.france_2.body": "I discovered many novels! I take Nextory everywhere with me!",
  "homepage.review.france_2.platform": "Google Play",
  "homepage.review.france_2.stars": "5",
  "homepage.review.france_2.username": "Sarah W.",
  "homepage.review.france_3.body": "Wide choice of books, ebooks and audios for a more than reasonable price.",
  "homepage.review.france_3.platform": "Google Play",
  "homepage.review.france_3.stars": "5",
  "homepage.review.france_3.username": "Aurore D.",
  "homepage.review.france_4.body": "Great experience. The application is very well done, I find plenty of reading material without spending hours of research. I love Nextory.",
  "homepage.review.france_4.platform": "Google Play",
  "homepage.review.france_4.stars": "5",
  "homepage.review.france_4.username": "Magalie F.",
  "homepage.review.germany_1.body": "Many different options for books, audiobooks, unlimited selection, I think that's great!",
  "homepage.review.germany_1.platform": "Google Play",
  "homepage.review.germany_1.stars": "5",
  "homepage.review.germany_1.username": "Dia B.",
  "homepage.review.germany_2.body": "Great books to listen to everywhere.",
  "homepage.review.germany_2.platform": "Google Play",
  "homepage.review.germany_2.stars": "5",
  "homepage.review.germany_2.username": "Katrin N.",
  "homepage.review.germany_3.body": "Great app that unites the worlds of books. I love it!",
  "homepage.review.germany_3.platform": "Google Play",
  "homepage.review.germany_3.stars": "5",
  "homepage.review.germany_3.username": "Äxel M.",
  "homepage.review.germany_4.body": "I'm a big fan of Nextory, good value for money, lots of choice.",
  "homepage.review.germany_4.platform": "iOS",
  "homepage.review.germany_4.stars": "5",
  "homepage.review.germany_4.username": "Stycja",
  "homepage.review.netherlands_1.body": "I honestly can't do without this app anymore. Listen to books all day long and everything I like is also on it. So great as far as I'm concerned, 5 stars.",
  "homepage.review.netherlands_1.platform": "iOS",
  "homepage.review.netherlands_1.stars": "5",
  "homepage.review.netherlands_1.username": "xMaraaaa",
  "homepage.review.netherlands_2.body": "Super fun, I can't live without Nextory anymore, I listen to it every evening and when I go on holiday I listen 24/7.",
  "homepage.review.netherlands_2.platform": "iOS",
  "homepage.review.netherlands_2.stars": "5",
  "homepage.review.netherlands_2.username": "Lauke",
  "homepage.review.netherlands_3.body": "Very nice app that makes housework a lot more fun!",
  "homepage.review.netherlands_3.platform": "Google Play",
  "homepage.review.netherlands_3.stars": "5",
  "homepage.review.netherlands_3.username": "Mirjam K.",
  "homepage.review.netherlands_4.body": "The range is extensive and up to date. I'm not really into buying apps, but I think this one is really worth the money.",
  "homepage.review.netherlands_4.platform": "Google Play",
  "homepage.review.netherlands_4.stars": "5",
  "homepage.review.netherlands_4.username": "Danielle",
  "homepage.review.norway_1.body": "Love the variety of books, and that the whole family can have their profiles. Fantastic app!",
  "homepage.review.norway_1.platform": "Google Play",
  "homepage.review.norway_1.stars": "5",
  "homepage.review.norway_1.username": "Teresa B.",
  "homepage.review.norway_2.body": "Lovely to listen to good books. Relaxing! Recommend Nextory.",
  "homepage.review.norway_2.platform": "Google Play",
  "homepage.review.norway_2.stars": "5",
  "homepage.review.norway_2.username": "Torill Mari H.",
  "homepage.review.norway_3.body": "I think it's nice that there is such a good selection of books, and once I start listening to an audiobook I can't stop reading.",
  "homepage.review.norway_3.platform": "iOS",
  "homepage.review.norway_3.stars": "5",
  "homepage.review.norway_3.username": "Lydbok*nørd",
  "homepage.review.norway_4.body": "Lots of good books in Norwegian, and a large selection of children's and youth books. The kids love the app.",
  "homepage.review.norway_4.platform": "iOS",
  "homepage.review.norway_4.stars": "5",
  "homepage.review.norway_4.username": "Hansabyen",
  "homepage.review.spain_1.body": "It’s the audiobook application that I like the most. It has options that I miss in others.",
  "homepage.review.spain_1.platform": "Google Play",
  "homepage.review.spain_1.stars": "5",
  "homepage.review.spain_1.username": "Saqqara D.",
  "homepage.review.spain_2.body": "The app is amazing! Thousands of books in your pocket. Just perfect.",
  "homepage.review.spain_2.platform": "Google Play",
  "homepage.review.spain_2.stars": "5",
  "homepage.review.spain_2.username": "Ascanio",
  "homepage.review.spain_3.body": "Compared to other audiobook services I've tried, Nextory has a huge library of books of all kinds. I really like the interface too.",
  "homepage.review.spain_3.platform": "iOS",
  "homepage.review.spain_3.stars": "5",
  "homepage.review.spain_3.username": "Pablo F.",
  "homepage.review.spain_4.body": "The best reading application for me, excellent book catalog and the option to decide between reading and audiobook on many occasions.",
  "homepage.review.spain_4.platform": "iOS",
  "homepage.review.spain_4.stars": "5",
  "homepage.review.spain_4.username": "Super dragona",
  "homepage.review.sweden_1.body": "Very good and huge selection of both books and magazines! Simple intuitive app!",
  "homepage.review.sweden_1.platform": "iOS",
  "homepage.review.sweden_1.stars": "5",
  "homepage.review.sweden_1.username": "The Bookmal",
  "homepage.review.sweden_2.body": "I love this app, you can listen and read lots of books without having to go to a library.",
  "homepage.review.sweden_2.platform": "iOS",
  "homepage.review.sweden_2.stars": "5",
  "homepage.review.sweden_2.username": "Cookiekaka",
  "homepage.review.sweden_3.body": "Many different audiobooks for all tastes. Easy to use. Satisfied!",
  "homepage.review.sweden_3.platform": "Google Play",
  "homepage.review.sweden_3.stars": "5",
  "homepage.review.sweden_3.username": "Ann-Sofie G.",
  "homepage.review.sweden_4.body": "It’s fantastic that my daughters read their stories themselves.",
  "homepage.review.sweden_4.platform": "Google Play",
  "homepage.review.sweden_4.stars": "5",
  "homepage.review.sweden_4.username": "Siv E.",
  "homepage.review.title": "Loved by thousands of readers",
  "homepage.showcase.body.family": "Create profiles for your family in seconds. Encourage good reading habits with exciting stories for all ages.",
  "homepage.showcase.link_1.family": "Ages 0–6",
  "homepage.showcase.link_1.slugtitle": "ages-0-6",
  "homepage.showcase.link_2.family": "Ages 6–9",
  "homepage.showcase.link_2.slugtitle": "ages-6-9",
  "homepage.showcase.link_3.family": "Ages 9–12",
  "homepage.showcase.link_3.slugtitle": "ages-9-12",
  "homepage.showcase.link_4.family": "Young Adult",
  "homepage.showcase.link_4.slugtitle": "teenage-and-young-adult",
  "homepage.showcase.title.family": "Share the love of reading with your family",
  "homepage.testimonial.microcopy": "Read 32 books between May and December",
  "homepage.testimonial.name": "Sofia Rasmussen",
  "homepage.testimonial.quote": "”I like that you can challenge each other and yourself. My daughter and I create challenges together, for example, reading ten books in four months.”",
  "homepage.this_is_nextory.body": "Get the Nextory app on your smartphone or tablet and take your favorite stories with you — on every adventure!",
  "homepage.this_is_nextory.bullet.audiobooks": "Audiobooks",
  "homepage.this_is_nextory.bullet.childrens_books": "Children’s books",
  "homepage.this_is_nextory.bullet.comics": "Comics",
  "homepage.this_is_nextory.bullet.ebooks": "E-books",
  "homepage.this_is_nextory.bullet.magazines": "Magazines",
  "homepage.this_is_nextory.bullet.planeta": "Entire Planeta catalog",
  "homepage.this_is_nextory.bullet.sleep_stories": "Sleep stories",
  "homepage.this_is_nextory.title": "Read and listen on the go",
  "homepage.usp1.body": "More than 41,000 people have given Nextory 5 stars on the App Store and Google Play.",
  "homepage.usp1.title": "A great experience",
  "homepage.usp2.body": "Read and listen to hundreds of thousands of titles. You can change or cancel your subscription anytime you like.",
  "homepage.usp2.title": "A wide selection",
  "homepage.usp3.body": "Set reading goals for yourself using the Book challenge. Follow your reading statistics with the Reading diary.",
  "homepage.usp3.title": "Challenge yourself",
  "login.action_forgot_password": "Forgot password?",
  "login.action_start_trial.link": "free trial.",
  "login.action_start_trial.text": "Don't have an account? Start your",
  "login.desktop.quote.author.pride": "- Janet Mock",
  "login.desktop.quote.title": "Don't follow the feed.\nFeed your brain.",
  "login.desktop.quote.title.pride": "I believe that telling our stories, first to ourselves and then to one another and the world, is a revolutionary act.",
  "login.desktop.quote_unknown": "Unknown",
  "login.error_no_email": "Enter your email address.",
  "login.error_no_password": "Enter your password.",
  "login.external_account.partner_free": "Free or Free Mobile",
  "login.external_account.partner_orange_tunisie": "Orange Tunisie",
  "login.external_account.partner_orange_tunisie.button_verify": "Verify my number",
  "login.external_account.partner_orange_tunisie.label_mobile": "Mobile number",
  "login.external_account.partner_orange_tunisie.pin.buttonLabel": "Log in to Nextory",
  "login.external_account.partner_orange_tunisie.pin.label": "Confirmation code",
  "login.external_account.partner_orange_tunisie.pin.legend": "Enter the code you received via text message.",
  "login.external_account.partner_orange_tunisie.pin.placeholder": "Enter the code you received via text message.",
  "login.external_account.partner_orange_tunisie.pin_send.message": "A confirmation code was sent to you via text message.",
  "login.external_account.partner_orange_tunisie.placeholder": "Enter your Orange mobile number",
  "login.external_account.partner_orange_tunisie.title": "Enter your number in the format XX XXX XXX or XXXXXXXX",
  "login.external_account.partner_orange_tunisie_microcopy": "Enter your mobile phone number in the format 53 123 456.",
  "login.external_account.partner_orange_tunisie_prefix": "216",
  "login.external_account.partner_sfr": "SFR or RED by SFR",
  "login.external_account.partner_videofutur": "VIDEOFUTUR",
  "login.external_account.title": "External login",
  "login.forgot_password.body": "Enter your email, and we’ll send a link that you can use to reset your password.",
  "login.forgot_password.error_email_doesnt_exist": "We don’t recognize this email. Make sure to enter the email you used when you signed up.",
  "login.forgot_password.success_screen.title": "Help is on the way! Keep an eye on your inbox.",
  "login.forgot_password.title": "Forgot password?",
  "login.input_label_password": "Password",
  "login.required_field.microcopy": "* Required field",
  "meta.author.description": "Audiobooks & E-Books by {authorName} 📖 Read or listen? It's up to you! ✓ Try {trialDays} days for free",
  "meta.author.title": "{authorName} – All Audiobooks & E-books",
  "meta.book.description": "Read \"{bookTitle}\" for free 📖 by {authorName} ✓ Available as {bookFormat} ✓ Try {trialDays} days for free.",
  "meta.book.description.with_narrator": "Read \"{bookTitle}\" for free 📖 by {authorName} ✓ Available as {bookFormat} ✓ Try {trialDays} days for free. Narrated by {narratorName}.",
  "meta.book.title": "{bookTitle} | {bookFormat} | {authorName} | Nextory",
  "meta.category.description": "Read books in the category {categoryName} for free. Try Nextory free of charge for {trialDays} days – cancel anytime. More than 350,000 books ✓ Read and listen as much as you like ✓",
  "meta.category.title": "{categoryName} – All Books | Read {trialDays} days for free | See top lists",
  "meta.default.description": "Find your and the children's favorites among hundreds of thousands of Audiobooks and E-books 📖",
  "meta.default.title": "Nextory: Audiobooks & E-Books",
  "meta.home.description": "Read or listen? It's up to you! ➤ Find your and the children's favorites among hundreds of thousands of Audiobooks and E-books 📖 Try {trialDays} days for free",
  "meta.home.title": "Nextory: E-Books & Audiobooks – Try {trialDays} days for free",
  "meta.login.description": "Log in to Nextory to handle your account – change settings, add profiles, update your subscription and more.",
  "meta.login.title": "Nextory: Log in",
  "meta.series.description": "Read {serieName} for free 📖 Try {trialDays} days for free",
  "meta.series.title": "{serieName} | Nextory",
  "migration.overtake.cta.download_app": "Download the new app",
  "migration.overtake.cta.learn_more": "Learn more",
  "migration.overtake.post_launch.content": "To continue reading and listening you need to download the new app. Log in with your usual credentials and keep reading and listening seamlessly.",
  "migration.overtake.post_launch.title": "Get the new app",
  "migration.overtake.pre_launch.content": "We’re building a better and faster app for you. The new app will be available to download on August 14th.",
  "migration.overtake.pre_launch.title": "A new app is on the way",
  "migration.underway.faq.answer_eight": "All valid vouchers can still be activated on the website.",
  "migration.underway.faq.answer_eleven": "Your reading progress and bookmarks are transferred to the new app so that you can continue where you left off.",
  "migration.underway.faq.answer_five": "The web application will no longer be available. You have to download the app on Google Play or App Store to enjoy a better experience.",
  "migration.underway.faq.answer_four": "All iOS devices with iOS verson 14 or above, and all Andriod devices with Andriod 5 and above.",
  "migration.underway.faq.answer_nine": "In the new app you can create child profiles. You're also able to set a PIN code that must be entered to change from a child profile. Only books for children under 12 are visible in this type of profile.",
  "migration.underway.faq.answer_one": "Your books and magazines are available in the new app and your bookmarks are synchronized as well. You will be able to continue reading and listening where you left off.",
  "migration.underway.faq.answer_seven": "You can find all your lists in the \"My library\" tab.",
  "migration.underway.faq.answer_six": "The terms of your subscription remain unchanged except for the rights associated with the profiles which replace the number of screens: in the Standard subscription, simultaneous reading on several profiles is not possible; in the Premium subscription, 2 profiles can use the application simultaneously; in the Family subscription, 4 profiles can use the application simultaneously.",
  "migration.underway.faq.answer_ten": "This new version of the application offers you three major new features: \n- Share your account with 3 other people: each profile gets its own recommendations and can create its own lists.\n- A bigger selection with better sorting and filtering options to make it easier to find what you're looking for.\n- Reading statistics where you can follow your reading over time.",
  "migration.underway.faq.answer_three": "This past year we've been building something bigger, better and more user-friendly than ever before. We wanted to offer a better experience with new features: more titles, more languages, 4 profiles, a special profile for kids only, an overview of the upcoming titles on the app, reading challenges and statistics!",
  "migration.underway.faq.answer_twelve": "You will need to redownload them in the new app in order to be able to enjoy them without an internet connection.",
  "migration.underway.faq.answer_two": "From January 10th 2024, you will no longer be able to use the Nextory app. Tap \"Download the new app\" to enjoy a brand new experience and find your books and magazines.",
  "migration.underway.faq.title_eight": "Can I still activate a voucher I've received?",
  "migration.underway.faq.title_eleven": "Will I be able to start where I left off in my books?",
  "migration.underway.faq.title_five": "Can I continue reading on the web?",
  "migration.underway.faq.title_four": "Which devices does the new app support?",
  "migration.underway.faq.title_nine": "Does the new application have parental controls?",
  "migration.underway.faq.title_one": "What happens to my books and magazines?",
  "migration.underway.faq.title_seven": "What happens to my lists?",
  "migration.underway.faq.title_six": "Is my subscription changing?",
  "migration.underway.faq.title_ten": "What's new in the app?",
  "migration.underway.faq.title_three": "Why are you releasing a new app?",
  "migration.underway.faq.title_twelve": "What happens to content I've downloaded?",
  "migration.underway.faq.title_two": "Can I continue using the Nextory app?",
  "migration.underway.post_launch.header.content": "Extra profiles, kids mode, more content, reading statistics and more. The new app is here! Download it now to enjoy all the new features.",
  "migration.underway.post_launch.header.sub_content": "The app is available for both iOS and Android",
  "migration.underway.post_launch.header.title": "The new app is here",
  "migration.underway.pre_launch.header.content": "This past year we've been building something bigger, better and more user-friendly than ever before. You can download the new app on January 10th, 2024.",
  "migration.underway.pre_launch.header.title": "A new app is on the way",
  "migration.underway.slider.action.next_slide": "Next slide",
  "migration.underway.slider.action.previous_slide": "Previous slide",
  "migration.underway.slider.button_title_one": "A bigger catalog",
  "migration.underway.slider.content_one": "With the new app comes a lot of new content in different languages. Sorting and filtering is also improved to make it easier to find what you’re looking for.",
  "migration.underway.slider.content_three": "See how your reading habits change over time and get fun insights on your most-read categories, reading time and more.",
  "migration.underway.slider.content_two": "In the new app you can create up to 3 extra profiles for your family. You can also create child profiles for the little ones.",
  "migration.underway.slider.image_alt_one": "An image showing the library in the app. On top are the most recent books and below are reading lists that contain books and magazines.",
  "migration.underway.slider.image_alt_three": "An image of graphs showing reading statistics month over month",
  "migration.underway.slider.image_alt_two": "An image showing different profiles",
  "migration.underway.slider.title_one": "A bigger catalog",
  "migration.underway.slider.title_three": "Reading statistics",
  "migration.underway.slider.title_two": "Create 3 extra profiles",
  "migration.underway.slider_header.content": "Profiles, more content, reading statistics and more.",
  "migration.underway.slider_header.title": "Here’s what you can expect",
  "migration.underway.three_questions.answer_one": "All you have to do is tap \"Download the app\", and you'll be redirected to the download page. Once you downloaded the app you'll be logged in automatically with your current login credentials. You'll find your books and magazines in the \"My library\" tab.",
  "migration.underway.three_questions.answer_three": "The price stays the same. You continue to pay the same price as today.",
  "migration.underway.three_questions.answer_two": "You can log in using the same email and password as today.",
  "migration.underway.three_questions.title_one": "Do I have to do anything?",
  "migration.underway.three_questions.title_three": "Is the price changing?",
  "migration.underway.three_questions.title_two": "Will my login information change?",
  "mypage.profile.edit": "Edit profile",
  "mypages.account.action_change_email": "Change email",
  "mypages.account.action_change_password": "Change password",
  "mypages.account.market": "Your market: {name}",
  "mypages.account.password_preview": "Password: ••••••••••••",
  "mypages.account.title": "Account",
  "mypages.action_download_app": "Download the app",
  "mypages.banner_add_more_profiles": "Psst. You can still add 3 more profiles!",
  "mypages.banner_update_payment": "Update your payment information.",
  "mypages.change_email.error_email_used_previous": "This is your current email address. Enter a different one.",
  "mypages.change_email.error_emails_dont_match": "The email addresses don’t match.",
  "mypages.change_password.error_password_similar_previous": "You're already using this password. Enter a different one.",
  "mypages.change_password.error_passwords_dont_match": "The passwords don’t match.",
  "mypages.change_password.snackbar_email_updated": "The email address has been updated.",
  "mypages.change_password.snackbar_password_updated": "The password has been updated.",
  "mypages.change_sub.pending.banner": "You’ve changed subscription to {name}. Your new subscription starts on your next payment date on {date}.",
  "mypages.collect_discount.partner_label.kjell": "Your Member Number at Kjell & Company",
  "mypages.collect_discount.unit.kjell": "Kjell & Company rewards",
  "mypages.collect_points.body": "Collect points monthly with one of our partners.",
  "mypages.collect_points.connected.body": "You’re collecting points with [partner]. To collect points with another partner, you first need to stop collecting points with [partner].",
  "mypages.collect_points.connected.indicator": "Collecting {unit}",
  "mypages.collect_points.cta.connect": "Collect points",
  "mypages.collect_points.cta.disconnect": "Stop collecting points",
  "mypages.collect_points.disconnect.dialogue.body": "Do you want to stop collecting {unit} with {partner}?",
  "mypages.collect_points.disconnect.dialogue.cant_reconnect.body": "Do you want to stop collecting points with [partner]? You won’t be able to collect points with this partner again.",
  "mypages.collect_points.disconnect.dialogue.title": "Stop collecting {unit}?",
  "mypages.collect_points.partner_callback.norwegian": "Forgot your Reward Number? Click here.",
  "mypages.collect_points.partner_label.sj": "SJ Prio number",
  "mypages.collect_points.title": "Points",
  "mypages.collect_points.unit.bbl": "bonus",
  "mypages.collect_points.unit.coop": "Coop bonus points",
  "mypages.collect_points.unit.klm": "Miles",
  "mypages.collect_points.unit.norwegian": "CashPoints",
  "mypages.collect_points.unit.sj": "SJ Prio-points",
  "mypages.information.label3": "Address",
  "mypages.information.label4": "Postal code",
  "mypages.information.label6": "City",
  "mypages.information.title": "Information",
  "mypages.limited_free_trial.activated.success.back.button": "Back to My pages",
  "mypages.limited_free_trial.activated.success.body": "Your subscription has been activated, and you’ve been charged the monthly price of {price}. Open the app to read and listen.",
  "mypages.limited_free_trial.activated.success.open_app.button": "Open app",
  "mypages.limited_free_trial.activated.success.title": "Nice! Now you can read and listen again.",
  "mypages.limited_free_trial_module.body": "To continue reading and listening, you can activate your subscription directly. You can also wait for {period} {periodUnit}, and your subscription will activate automatically.",
  "mypages.limited_free_trial_module.button": "Activate subscription",
  "mypages.limited_free_trial_module.title": "You’ve reached your {hours} hour limit",
  "mypages.limited_paying_member.module.body": "Don’t worry! Keep reading and listening right away by renewing your subscription now. This means that your 30-day payment cycle starts from today. Otherwise, your subscription will be renewed automatically in {period} {periodUnit}.",
  "mypages.limited_paying_member.module.button": "Renew now",
  "mypages.limited_paying_member.renewed.success.body": "Your subscription is now renewed, and you’ve been charged the monthly price of {price}. Open the app to continue where you left off!",
  "mypages.listening_time.active": "Listening time (audio books): {hours} h {minutes} min",
  "mypages.listening_time.renewal": "Listening time (audio books): 0 h 0 min, pending renewal",
  "mypages.logged_in_user.user_email": "david.bredenberg@nextory.com",
  "mypages.max_profiles_amount": "You can have maximum {n} profiles. Delete a profile to add another one.",
  "mypages.parental_control.body": "Make reading safer for the little ones by blocking access to adult content.",
  "mypages.parental_control.title": "Parental controls",
  "mypages.parental_control.toggle": "Block adult content",
  "mypages.payment.delete_payment.error.snackbar": "We couldn't delete your payment details.",
  "mypages.payment.delete_payment.snackbar": "Your payment details were deleted.",
  "mypages.payment.info_free_trial_end": "Your free trial period ends on [date].\nAfter your free trial period, you will pay [amount] per month.",
  "mypages.payment.info_gift_card_used_up": "Your gift card will be depleted on [date].",
  "mypages.payment.info_subscription_cancelled_date": "You canceled your subscription on {date}.",
  "mypages.payment.info_subscription_cancelled_date_days_left": "You cancelled your subscription on {cancelDate}, but you can read and listen as usual until {subscriptionStopDate}.",
  "mypages.payment.payment_history.action_receipt": "Receipt",
  "mypages.payment.payment_history.transaction_amount": "Cost:",
  "mypages.payment.payment_history.transaction_date": "05-24-2020",
  "mypages.payment.update_payment.body": "Your new payment details will be used for the next payment on {date}.",
  "mypages.payment.update_payment.error": "Your payment didn't go through. Double check your details.",
  "mypages.payment.update_payment.snackbar": "Your payment details were saved.",
  "mypages.payment_history.receipt_screen.button_download_receipt": "Download receipt",
  "mypages.payment_history.receipt_screen.title": "Receipt",
  "mypages.pp.title": "Privacy Policy",
  "mypages.profiles.creation_success": "The profile {name} was created. Nice!",
  "mypages.profiles.delete_profile": "Remove profile",
  "mypages.profiles.delete_profile_confirmation": "Do you want to delete the profile {name}?",
  "mypages.profiles.deletion_success": "The profile {name} was removed.",
  "mypages.profiles.edit_profile": "Edit profile",
  "mypages.profiles.edition_success": "The changes for the profile {name} were saved.",
  "mypages.profiles.error_creation": "Something went wrong while creating your profile. Try again.",
  "mypages.profiles.error_edition": "Something went wrong while editing your profile. Try again.",
  "mypages.profiles.label_child_profile": "Child profile",
  "mypages.profiles.label_name": "Name",
  "mypages.subscription.change.button": "Change to {name}",
  "mypages.subscription.change.success.body": "The new price will take effect on your next payment date on {date}. Enjoy your new subscription!",
  "mypages.subscription.change.success.cta": "Go to My pages",
  "mypages.subscription.change.success.title": "Yay! You changed to {name}.",
  "mypages.subscription.downgrade.success.body": "Your subscription will change on your next payment date on {date}.",
  "mypages.subscription.downgrade.success.title": "You changed to {name}",
  "mypages.subscription.hours_consumed.limited_free_trial.info": "Time you’ve read and listened during your free trial",
  "mypages.subscription.info_account_created_through_partner": "Your subscription is handled via {partner}. Reach out to them if you want to make changes to your subscription.",
  "mypages.subscription.limited_free_trial.label": "Free trial",
  "mypages.subscription.time_limited.trial_period.info.body": "Your subscription lets you read and listen for {n} hours per month. But during your free trial you can read and listen as much as you like. Enjoy!",
  "mypages.subscription_payment.action_change_payment": "Change payment method",
  "mypages.subscription_payment.action_change_subscription": "Change subscription",
  "mypages.subscription_payment.action_delete_payment": "Remove this card",
  "mypages.subscription_payment.action_view_payments": "View payment history",
  "mypages.subscription_payment.body1": "{number} profile can use the app at the same time.|{number} profiles can use the app at the same time.",
  "mypages.subscription_payment.body2": "Next payment: {amount} on {date}",
  "mypages.subscription_payment.button.activate_subscription": "Activate my subscription",
  "mypages.subscription_payment.button.button_subscribe": "Subscribe",
  "mypages.subscription_payment.button.reactivate_subscription": "Reactivate subscription",
  "mypages.subscription_payment.button_cancel_subscription": "Cancel subscription",
  "mypages.subscription_payment.card_nr": "**** **** **** 3332",
  "mypages.subscription_payment.free_trial": "Your free trial ends on {date}",
  "mypages.subscription_payment.icon_active": "Active",
  "mypages.subscription_payment.icon_cancelled": "Canceled",
  "mypages.subscription_payment.subtitle1": "Family with 4 profiles",
  "mypages.subscription_payment.subtitle2": "Payment method:",
  "mypages.subscription_payment.subtitle3": "Payment history",
  "mypages.subscription_payment.time_remaining": "{hours} hours {minutes} minutes",
  "mypages.subscription_payment.time_remaining.body": "Your {hours} hours will refill in {days} days and are shared between all profiles.",
  "mypages.subscription_payment.time_remaining.title": "Time left",
  "mypages.subscription_payment.title": "Subscription & payment",
  "mypages.tc.title": "Terms & Conditions",
  "mypages.time_consumption.action_disclose_faq": "How do we calculate this?",
  "mypages.time_consumption.action_hide_activity": "Hide activity",
  "mypages.time_consumption.faq.body1": "Listening to an audiobook at normal speed (x1) for an hour subtracts one hour from that period's time. If you play the book faster, for example, at a speed of 1.5, the time will be consumed 1.5 times faster. For example, if you listen for 1 hour at a speed of x2, it would count as 2 hours. If you play the book slower, the time will be consumed slower.",
  "mypages.time_consumption.faq.body2": "For e-books, one hour is calculated based on a certain amount of characters. That means you can read as fast or slowly as you want. Flipping through pages doesn’t count toward the time spent. \n\nIf you read or listen to the same segment of a book more than once during the same period, the time is only counted once. This also applies to when 2 or more profiles read or listen to the same segment of a book during the same period.",
  "mypages.time_consumption.faq.intro": "Your hours renew every payment period and are shared between all profiles.",
  "mypages.time_consumption.faq.subtitle1": "Audiobooks",
  "mypages.time_consumption.faq.subtitle2": "E-books",
  "mypages.time_consumption.faq.title": "How do we calculate how many hours you read and listen?",
  "mypages.time_limited.action.show_activity": "Show activity",
  "mypages.time_restricted.body": "Your hours are refilled in {period} {periodUnit} and are shared between all profiles.",
  "mypages.time_restricted.time_consumed": "{hour} h {min} min",
  "mypages.time_restricted.time_consumed_min": "{min} min",
  "mypages.time_restricted.time_read": "{consumed} hours of {allowed}",
  "mypages.time_restricted.time_read.title": "Time read",
  "mypages.time_restricted.time_read_per_profile": "Time read per profile",
  "mypages.title": "My Pages",
  "mypages.update_email.label_current_email": "Current email",
  "mypages.update_email.label_current_password": "Current password",
  "mypages.update_email.label_new_email": "New email",
  "mypages.update_email.label_new_password": "New password",
  "mypages.update_email.label_repeat_email": "Repeat new email",
  "mypages.update_email.label_repeat_password": "Repeat new password",
  "mypages.update_email.title": "Change email",
  "mypages.update_info.snackbar.info_updated": "Your information was updated.",
  "n_picker.list.monthly_hours_info_silver": "30 hours",
  "partner.collect_discount.title": "Earn {unit} with Nextory",
  "partner.collect_points.body": "Enter your details to get started.",
  "partner.collect_points.body.kjell": "As a member of Kjell & Company, you get to try Nextory for free for 2 months. Every three months as a paying Nextory customer, you receive a SEK 50 discount check at Kjell & Company. Already a Nextory customer? Log in and link your account to your Kjell & Company membership to receive your discount check every three months.",
  "partner.collect_points.ssn.tooltip": "We need your SSN to send the Coop-points to the right account.",
  "partner.collect_points.title": "Collect {unit} with Nextory",
  "payment.ideal.payment_option.label": "How would you like to pay?",
  "providers.errors.free.cancel_login": "Something went wrong while logging in. Try again.",
  "reactivate_subscription.nonmember.title": "You’ve already used your free trial and will be charged directly.",
  "redeem.campaign.error_page.body": "Try another one, or {link} if you think we’ve made a mistake.",
  "redeem.campaign.error_page.body.link": "get in touch",
  "redeem.campaign.error_page.invalid_code.title": "We don’t recognize this code",
  "redeem.campaign.error_page.microcopy_continue_without_code": "Continue without a code",
  "redeem.campaign.error_page.title.code_consumed": "You’ve already used this code",
  "redeem.campaign.error_page.title.code_invalid": "We don't recognize this code.",
  "redeem.campaign.error_page.title.new_member": "This code can only be used by new customers",
  "redeem.campaign.offer_summary.discount.body": "{percent}% off for {period} {periodUnit}",
  "redeem.campaign.offer_summary.discount.summary": "{percent}% off for {period} {periodUnit}, then {price}/month",
  "redeem.campaign.offer_summary.discount.unlimited.body": "{percent}% off",
  "redeem.campaign.offer_summary.discount.unlimited.summary": "{percent}% off, then {price}/month",
  "redeem.campaign.offer_summary.discount_free_days.body": "{freeDays} free days, then {percent}% off for {period} {periodUnit}",
  "redeem.campaign.offer_summary.discount_free_days.summary": "{freeDays} free days, then {percent}% off for {period} {periodUnit}",
  "redeem.campaign.offer_summary.discount_free_days.unlimited.body": "{freeDays} free days, then {percent}% off",
  "redeem.campaign.offer_summary.discount_free_days.unlimited.summary": "{freeDays} free days, then {percent}% off",
  "redeem.campaign.offer_summary.fixed.body": "{price}/month for {period} {periodUnit}, no matter which subscription you choose",
  "redeem.campaign.offer_summary.fixed.summary": "{price}/month for {period} {periodUnit}",
  "redeem.campaign.offer_summary.fixed.unlimited.body": "{price}/month, no matter which subscription you choose",
  "redeem.campaign.offer_summary.fixed.unlimited.summary": "{price}/month",
  "redeem.campaign.offer_summary.fixed_free_days.body": "{freeDays} days free, then {price}/month for {period} {periodUnit}, no matter which subscription you choose",
  "redeem.campaign.offer_summary.fixed_free_days.summary": "{freeDays} days free, then {price}/month for {period} {periodUnit}",
  "redeem.campaign.offer_summary.fixed_free_days.unlimited.body": "{freeDays} days free, then {price}/month, no matter which subscription you choose",
  "redeem.campaign.offer_summary.fixed_free_days.unlimited.summary": "{freeDays} days free, then {price}/month",
  "redeem.campaign.offer_summary.free_days.body": "{period} {periodUnit} for free",
  "redeem.campaign.offer_summary.free_days.summary": "{period} {periodUnit} for free, then {price}/month",
  "redeem.campaign.offer_summary.title": "Your offer",
  "redeem.campaign.title": "Enter your campaign code",
  "redeem.error.code_already_used": "You’ve already used this code.",
  "redeem.error.code_expired": "This code has expired.",
  "redeem.error.not_active_user": "You need to have an active subscription to use this code.",
  "redeem.error.not_new_customer": "This code can only be used by new customers.",
  "redeem.error.not_nonmember": "You can only use this code if you've cancelled your subscription.",
  "redeem.gift_card.body": "Sign up to redeem your gift card.",
  "redeem.gift_card.sub_picker.days": "Days you can read and listen",
  "redeem.gift_card.sub_picker.days.title": "Your gift card gives you",
  "redeem.gift_card.sub_picker.monthly_hour_period.title": "Hours you can read and listen per 30 days",
  "redeem.gift_card.sub_picker.non_paying.body": "You won’t be charged after your gift card expires.",
  "redeem.gift_card.sub_picker.non_paying.cta": "Choose {name}",
  "redeem.gift_card.sub_picker.paying.body": "Once your gift card expires you’ll be charged the monthly price of the subscription you pick.",
  "redeem.gift_card.success.account.setup.title": "Enter your details",
  "redeem.gift_card.success.body": "Personalize your account to get the best experience inside the app.",
  "redeem.gift_card.success.cta": "Personalize account",
  "redeem.gift_card.success.title": "Your gift card has been activated!",
  "redeem.gift_card.title": "Enter your gift card code",
  "redeem.giftcard.error.already_redeemed": "You’ve already redeemed this gift card.",
  "redeem.giftcard.error.expired": "This gift card has expired.",
  "redeem.giftcard.error.invalid_giftcard": "We don’t recognize this gift card. Get in touch if you think we’ve made a mistake.",
  "redeem.giftcard.label": "Gift card code",
  "redeem.giftcard.title": "Redeem your gift card",
  "redeem.label": "Enter code",
  "redeem.title": "Redeem your gift card or campaign code",
  "referral.landing_page.body": "Disa has given you 60 days for free. Read and listen to books anywhere and any time you like. The offer is valid for new customers.",
  "referral.landing_page.button": "Try 60 days for free",
  "referral.landing_page.title": "Claim your free trial",
  "reg_funnel.dimoco.body": "To pay with your phone bill, enter your phone number to receive a verification code.",
  "reg_funnel.dimoco.verification.error": "Couldn't validate the code. Please try again.",
  "reg_funnel.dimoco.verification.no_code": "Didn’t get a code? Go back to confirm your phone number and try again.",
  "reg_funnel.dimoco.verification_code.body": "A verification code has been sent to:",
  "reg_funnel.dimoco.verification_code.button": "Confirm payment",
  "reg_funnel.dimoco.verification_code.label": "Verification code",
  "reg_funnel.payment.module.bullet1": "Just making sure you're a real person.",
  "reg_funnel.payment.module.bullet2": "It's easy to keep reading and listening after your free trial.",
  "reg_funnel.payment.module.bullet3": "You're in control — cancel any time.",
  "reg_funnel.payment.module.divider_dimoco": "Pay with your phone bill",
  "reg_funnel.payment.module.header": "Confirm your account",
  "reg_funnel.payment.module.sub_header2": "Payment method",
  "regfunnel.account_creation.body": "Enter your details for a more personalized experience.",
  "regfunnel.account_creation.button_save": "Save and continue",
  "regfunnel.account_creation.from_coupon": "Your gift card or promotional code has been activated on your account.",
  "regfunnel.account_creation.module.label_female": "Female",
  "regfunnel.account_creation.module.label_firstname": "First name",
  "regfunnel.account_creation.module.label_male": "Male",
  "regfunnel.account_creation.module.label_nonbinary": "Non-binary",
  "regfunnel.account_creation.module.label_phone": "Phone number",
  "regfunnel.account_creation.module.label_surname": "Surname",
  "regfunnel.account_creation.module.profile_title": "Your profile|Add another profile",
  "regfunnel.account_creation.module.tooltip_phone": "We only use it if you need help retrieving a lost account.",
  "regfunnel.account_creation.module_title": "Your details",
  "regfunnel.account_creation.title": "Welcome to Nextory!",
  "regfunnel.change_subscription.legacy.current_subscription": "Your currently have {name} for {price}/mth.",
  "regfunnel.change_subscription.legacy.price": "Price per month",
  "regfunnel.change_subscription.legacy.title": "We have new subscriptions",
  "regfunnel.change_subscription.legacy.usp": "Unlimited reading and listening",
  "regfunnel.create_account.body": "Two more steps, and you’re done! We don't like paperwork either.",
  "regfunnel.create_account.input_label_email": "Email",
  "regfunnel.create_account.input_label_password": "Create password",
  "regfunnel.create_account.input_label_repeat_email": "Repeat email address",
  "regfunnel.create_account.microcopy_step": "Step {current} of {last}",
  "regfunnel.create_account.microcopy_step2": "Step 2 of 3",
  "regfunnel.create_account.microcopy_step3": "Step 3 of 3",
  "regfunnel.create_account.module.disclaimer": "Sign up for our newsletter for a chance to win 6 free months. There’s a new winner every month!",
  "regfunnel.create_account.module.title": "Create your account",
  "regfunnel.create_account.title": "Enter your details and start your free trial",
  "regfunnel.create_account.title.no_free_trial": "Create an account to enjoy your offer",
  "regfunnel.email.error.incorrect_email_address": "Double check the email address.",
  "regfunnel.limited_free_trial.body": "During your free trial of {period} {periodUnit}, you can read and listen for {hours} hours in total.",
  "regfunnel.limited_free_trial.payment_summary.body": "{period} {periodUnit} for free with {hours} hours of reading and listening",
  "regfunnel.non_member.starting_screen.body": "You’ve already used your free trial, so when you reactive your subscription you’ll be charged right away.",
  "regfunnel.non_member.summary.price_starting_today": "{price}/month starting today",
  "regfunnel.payment.button_start_free_trial": "Start your free trial",
  "regfunnel.payment.button_subscribe": "Subscribe",
  "regfunnel.payment.info_bulletpoint_cancellation": "No obligations. Cancel anytime.",
  "regfunnel.payment.info_bulletpoint_device": "Read and listen on mobile and tablet.",
  "regfunnel.payment.info_bulletpoint_price": "Cancel before {date}, and you’ll pay nothing.",
  "regfunnel.payment.module.card_cvv_placeholder": "123",
  "regfunnel.payment.module.card_expiry_placeholder": "MM/YY",
  "regfunnel.payment.module.card_nr_placeholder": "1111 2222 3333 4444",
  "regfunnel.payment.module.change_plan": "Change",
  "regfunnel.payment.module.error": "Your payment didn't go through. Double check your details.",
  "regfunnel.payment.module.free_period": "{days} day for free | {days} days for free",
  "regfunnel.payment.module.label_card_cvv": "CVC/CVV",
  "regfunnel.payment.module.label_card_expiry": "Expiry date",
  "regfunnel.payment.module.label_card_nr": "Card number",
  "regfunnel.payment.module.microcopy.disclaimer_info1": "To make sure you’re a real person.",
  "regfunnel.payment.module.microcopy.disclaimer_info2": "So that you can continue reading and listening after your trial period.",
  "regfunnel.payment.module.microcopy.disclaimer_payment": "No money will be taken from your account during your free trial period. You can cancel your subscription at any time.",
  "regfunnel.payment.module.microcopy.disclaimer_payment.no_free_trial": "You can cancel your subscription at any time.",
  "regfunnel.payment.module.microcopy.disclaimer_title": "Why do we ask for your payment details?",
  "regfunnel.payment.module.microcopy_secure_server": "Secure Server (SSL encrypted)",
  "regfunnel.payment.module.price_info": "{pricePerMonth}/month starting {startingDate}",
  "regfunnel.payment.payment_method_trustly": "Trustly",
  "regfunnel.payment.title": "Payment",
  "regfunnel.plan_picker.body": "You can change your subscription at any time.",
  "regfunnel.plan_picker.expired_or_invalid_coupon": "Your promotional code or gift card is no longer valid. Please choose an offer from those below or contact us.",
  "regfunnel.plan_picker.list.audiobooks_title": "Audiobooks",
  "regfunnel.plan_picker.list.cancel_title": "Cancel whenever you want",
  "regfunnel.plan_picker.list.device_title": "Mobile and tablet",
  "regfunnel.plan_picker.list.languages_title": "Multiple languages",
  "regfunnel.plan_picker.list.monthly_hours_info_gold_family": "Unlimited",
  "regfunnel.plan_picker.list.monthly_hours_title": "Hours you can read and listen per month",
  "regfunnel.plan_picker.list.monthly_price_info_silver": "€9.99",
  "regfunnel.plan_picker.list.monthly_price_title": "Monthly price after your free period ending Feb. 22, 2022",
  "regfunnel.plan_picker.list.offline_mode_title": "Offline mode",
  "regfunnel.plan_picker.list.simultaneous_devices_title": "Simultaneous devices",
  "regfunnel.plan_picker.list.simultaneous_profiles_info_family": "2-4",
  "regfunnel.plan_picker.list.simultaneous_profiles_title": "Profiles that can use the app at the same time",
  "regfunnel.plan_picker.title": "Pick a subscription",
  "regfunnel.plan_picker_family.2profiles_price": "199 kr/month",
  "regfunnel.plan_picker_family.3profiles_price": "239 kr/month",
  "regfunnel.plan_picker_family.4profiles_price": "279 kr/month",
  "regfunnel.plan_picker_family.title": "How many are you that will read or listen at the same time?",
  "regfunnel.pre_create_account.body": "To start reading and listening you need to create an account.",
  "regfunnel.pre_create_account.button_create_account": "Create account",
  "regfunnel.pre_create_account.title": "Create an account",
  "regfunnel.pre_plan_picker.body_first_bulletpoint": "Pay nothing until your free trial ends",
  "regfunnel.pre_plan_picker.body_first_bulletpoint.klm": "Get 500 Miles when your trial is over",
  "regfunnel.pre_plan_picker.body_second_bulletpoint": "Read and listen on mobile and tablet",
  "regfunnel.pre_plan_picker.body_second_bulletpoint.klm": "Earn Miles each month",
  "regfunnel.pre_plan_picker.body_third_bulletpoint": "Cancel whenever you want",
  "regfunnel.pre_plan_picker.title": "Choose your subscription",
  "regfunnel.profiles.body": "You can create up to {n} additional profiles for your loved ones. Every profile gets its own unique book recommendations.",
  "regfunnel.profiles.body.ab_test": "Create up to three additional profiles for your loved ones. Every profile gets its own library, recommendations, and reading statistics.",
  "regfunnel.profiles.button_send_link": "Send login link",
  "regfunnel.profiles.label_name": "Name",
  "regfunnel.profiles.login_link_body": "Send a login link to a person you’ve created a profile for. By sharing it with them, they won’t need to enter an email address or password when they log in.",
  "regfunnel.profiles.login_link_title": "What's this?",
  "regfunnel.profiles.module.title": "Create profile",
  "regfunnel.profiles.module_edit_profile.title": "Edit profile",
  "regfunnel.profiles.profile_info_non_family": "Your subscription lets 1 profile read and listen at a time. Upgrade to Family if you want more profiles to be able to read and listen at the same time.",
  "regfunnel.profiles.snackbar_link_copied": "The link has been copied.",
  "regfunnel.profiles.title": "Share your account with your loved ones",
  "regfunnel.providers.errors.free.create_account_first_body": "Sign up to Nextory to log in with Free.",
  "regfunnel.providers.errors.free.create_account_first_cta": "Sign up",
  "regfunnel.providers.free_subtitle": "To receive the Free exclusive offer, please log in using the login details for your Free or Free Mobile account.",
  "regfunnel.providers.free_title": "Enter the login details for your Free account",
  "regfunnel.providers.login_with_free": "Login with Free",
  "regfunnel.register.dimoco.legal.orange": "By clicking the button, you confirm that you are the account owner or have permission from the account owner to make purchases and that you agree to the {0}. You also declare that you agree to the immediate start of performance and that you are aware of the loss of the right to terminate the contract. The charges will be reflected on your mobile bill or deducted from your prepaid account. Mobile network charges may apply. Payment through your mobile operator will be subject to the {1}.",
  "regfunnel.register.dimoco.legal.zahl-einfach": "Price for the subscription: {price} (incl. VAT) per month. Billing is done via the mobile phone number. The subscription can also be cancelled with one day's notice up to the end of the respective billing period via {0}.",
  "regfunnel.register.dimoco.orange.specific_conditions": "Specific Conditions",
  "regfunnel.registration_complete.action_get_in_touch_link": "Get in touch",
  "regfunnel.registration_complete.action_get_in_touch_text": "Questions?",
  "regfunnel.registration_complete.module_desktop_body": "Or download the app and ask the person who owns the account to help you log in.",
  "regfunnel.registration_complete.module_desktop_title": "Open this link on your phone or tablet to log in directly",
  "regfunnel.registration_complete.module_download_app_title": "Download the app to get started",
  "regfunnel.returning_member.body": "You’ve already used your free trial, but you’re welcome to continue using Nextory at the regular price.",
  "regfunnel.returning_member.disclaimer": "I understand that I won’t get another free trial period and will be charged.",
  "regfunnel.returning_member.title": "Welcome back!",
  "regfunnel.success.screen_with_qr.body": "Download the app by scanning the code.",
  "regfunnel.unlimited_free_trial.body": "During your free trial of {period} {periodUnit}, you can read and listen as much as you like.",
  "regfunnel.unlimited_free_trial.payment_summary.body": "Read and listen for free as much as you like for {period} {periodUnit}, then {price}/month.",
  "register.already_have_account.text": "Already have an account?",
  "register.already_member.link": "Log in",
  "register.already_member.text": "Already have a subscription?",
  "search.ghostcopy": "What would you like to read?",
  "search.no_result.did_you_mean": "Did you mean",
  "search.no_result.suggested_fixes": "• Check the spelling\n• Try using a different word",
  "search.no_results": "Hmm, we couldn’t find “Hry potr”.",
  "search.page": "Page 1 of 34",
  "signup.error_email_already_exists": "We recognize this email address. Do you want to log in?",
  "simultaneous_profiles.override.consumed_hours.faq.body": "You can listen and read up to 30 hours per payment period. The hours are shared across all profiles. If you'd like to read and listen for more than 30 hours, you can upgrade to a Gold or Family subscription.",
  "simultaneous_profiles.override.consumed_hours.faq.title": "I don't have any hours left. What do I do?",
  "simultaneous_profiles.override.logged_out.faq.body": "You can add up to 4 profiles, but only 1 profile can use the app at a time. That means that if profile 1 is using the app and profile 2 logs in, profile 1 will be logged out. If you would like more profiles to be able to use the app at the same time, you can upgrade to a Family subscription.",
  "simultaneous_profiles.override.logged_out.faq.title": "Why was I logged out?"
})